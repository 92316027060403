import appLanguages from '@utils/app-languages';
import firebase from '@utils/firebase';
import {
  INIT_AUTH,
  INIT_AUTH_REQUEST,
  LOGIN_REQUEST,
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_TOKEN,
  LOGOUT,
  LOGOUT_REQUEST,
} from '.';
import { store } from '../reducers';
import { openSnackbarMessage } from './appsActions';

const initAuthRequest = () => ({ type: INIT_AUTH_REQUEST });
const initAuthSuccess = (user, idToken) => ({ type: INIT_AUTH, user, idToken });
const loginRequest = (type) => ({ type });
const loginSuccess = (type, result) => ({ type, result });
const loginFailure = (type, error) => ({ type, error });
const logoutRequest = () => ({ type: LOGOUT_REQUEST });
const logoutSuccess = () => ({ type: LOGOUT });
const logoutFailure = (error) => ({ type: LOGOUT, error });

export const initAuth = () => (dispatch) => {
  dispatch(initAuthRequest());
  firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
      dispatch(initAuthSuccess(null, null));
    } else {
      user
        .getIdToken(false)
        .then((idToken) => {
          dispatch(initAuthSuccess(user, idToken));
        })
        .catch((error) => {
          window.console.error(error);
          window.location.reload();
        });
    }
  });
};

export const loginWithEmail = (email, password) => async (dispatch) => {
  const lang = store.getState().apps.currentLanguage;
  dispatch(loginRequest(LOGIN_REQUEST));

  try {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    dispatch(loginSuccess(LOGIN_WITH_EMAIL, response));
  } catch (error) {
    dispatch(loginFailure(LOGIN_WITH_EMAIL, error));
    switch (error.code) {
      case 'auth/invalid-email':
        return dispatch(
          openSnackbarMessage('error', appLanguages.incorrectEmailFormatMessage[lang]),
        );
      case 'auth/user-not-found':
        return dispatch(openSnackbarMessage('error', appLanguages.notRegisteredEmailMessage[lang]));
      case 'auth/wrong-password':
        return dispatch(openSnackbarMessage('error', appLanguages.invalidPasswordMessage[lang]));
      default:
        return dispatch(openSnackbarMessage('error', `${error.message} [${error.code}]`));
    }
  }
};

export const loginWithCustomToken = (cToken) => (dispatch) => {
  dispatch(loginRequest(LOGIN_REQUEST));
  firebase
    .auth()
    .signInWithCustomToken(cToken)
    .then((result) => {
      dispatch(loginSuccess(LOGIN_WITH_TOKEN, result));
    })
    .catch((error) => {
      dispatch(loginFailure(LOGIN_WITH_TOKEN, error));
      console.log(`login with token error.code: ${error.code}`);
      switch (error.code) {
        case 'auth/invalid-email':
          dispatch(openSnackbarMessage('error', 'Email address format is incorrect.'));
          break;
        case 'auth/user-not-found':
          dispatch(openSnackbarMessage('error', 'Email address is not registered.'));
          break;
        case 'auth/wrong-password':
          dispatch(openSnackbarMessage('error', 'Invalid password.'));
          break;
        default:
          dispatch(openSnackbarMessage('error', `${error.message} [${error.code}]`));
      }
    });
};

export const logout = () => (dispatch) => {
  dispatch(logoutRequest());
  firebase
    .auth()
    .signOut()
    .then(() => dispatch(logoutSuccess()))
    .catch((error) => {
      window.console.error(error);
      dispatch(logoutFailure(error));
    });
};
