import { TSupportedLanguages } from '@models/supported-languages';

export default LangSettings({
  /**
   * ダッシュボード
   */
  // ヘッダー
  dashboard: {
    ja: 'ダッシュボード',
    en: 'Dashboard',
    zhcn: '仪表板',
    zhtw: '儀表板',
    ko: '대시 보드',
    th: 'หน้าแรก',
    vi: 'Trang chủ',
  },
  section: {
    ja: 'セクション',
    en: 'Section',
    zhcn: '部分',
    zhtw: '部分',
    ko: '섹션',
    th: 'ส่วน',
    vi: 'Phần',
  },
  chat: {
    ja: 'チャット',
    en: 'Chat',
    zhcn: '聊天',
    zhtw: '聊天',
    ko: '채팅',
    th: 'การสนทนา',
    vi: 'Trò chuyện',
  },
  masterManagement: {
    ja: 'マスタ管理',
    en: 'Master Management',
    zhcn: '管理大师',
    zhtw: '管理大師',
    ko: '마스터 관리',
    th: 'การจัดการหลัก',
    vi: 'Quản lý chính',
  },
  tempRegistration: {
    ja: '仮ユーザ登録',
    en: 'Temporary User Registration',
    zhcn: '临时用户注册',
    zhtw: '臨時用戶註冊',
    ko: '임시 사용자 등록',
    th: 'การลงทะเบียนชั่วคราว',
    vi: 'Đăng ký tạm thời',
  },
  tempRegistrationApproval: {
    ja: '仮登録ユーザ承認',
    en: 'Temporary Registered User Approval',
    zhcn: '临时注册用户批准',
    zhtw: '臨時註冊用戶批准',
    ko: '임시 사용자 등록 승인',
    th: 'การอนุมัติผู้ใช้ที่ลงทะเบียนชั่วคราว',
    vi: 'Phê duyệt người dùng đăng ký tạm thời',
  },
  approvalComment: {
    ja: '承認コメント',
    en: 'Approval Comment',
    zhcn: '审批意见',
    zhtw: '審批意見',
    ko: '승인 코멘트',
    th: 'ความคิดเห็นการอนุมัติ',
    vi: 'Bình luận phê duyệt',
  },
  tempUserApprove: {
    ja: '承認する',
    en: 'Approve',
    zhcn: '批准',
    zhtw: '批准',
    ko: '승인',
    th: 'อนุมัติ',
    vi: 'Phê duyệt',
  },
  tempUserReject: {
    ja: '承認しない',
    en: 'Reject',
    zhcn: '拒絕',
    zhtw: '拒絕',
    ko: '거절',
    th: 'ปฏิเสธ',
    vi: 'Từ chối',
  },
  languageSetting: {
    ja: '言語設定',
    en: 'Language Setting',
    zhcn: '语言设定',
    zhtw: '語言設定',
    ko: '언어 설정',
    th: 'การตั้ค่าภาษา',
    vi: 'Cài đặt ngôn ngữ',
  },
  login: {
    ja: 'ログイン',
    en: 'Login',
    zhcn: '登录',
    zhtw: '登錄',
    ko: '로그인',
    th: 'เข้าสู่ระบบ',
    vi: 'Đăng nhập',
  },
  openDashboard: {
    ja: 'ダッシュボードを開く',
    en: 'Open Dashboard',
    zhcn: '打开仪表板',
    zhtw: '打開儀表板',
    ko: '대시 보드를 열기',
    th: 'เปิดแดชบอร์ด',
    vi: 'Mở bảng điều khiển',
  },
  openApp: {
    ja: '流通アプリを開く',
    en: 'Open Distribution Application',
    zhcn: '开启分发程序',
    zhtw: '開啟分發程序',
    ko: '유통 응용 프로그램을 열기',
    th: 'เปิดแอปการจัดส่ง',
    vi: 'Mở ứng dụng phân phối',
  },
  openAppManual: {
    ja: 'マニュアルを開く',
    en: 'Open Manual',
    zhcn: '开启应用程式手册',
    zhtw: '開啟應用程式手冊',
    ko: '매뉴얼 열기',
    th: 'เปิดคู่มือ',
    vi: 'Mở tài liệu hướng dẫn',
  },
  logout: {
    ja: 'ログアウト',
    en: 'Logout',
    zhcn: '登',
    zhtw: '登出',
    ko: '로그 아웃',
    th: 'ออกจากระบบ',
    vi: 'Đăng xuất',
  },
  deleteLocation: {
    ja: '拠点を削除',
    en: 'Delete Location',
    zhcn: '削除地点',
    zhtw: '削除地點',
    ko: '지점 삭제',
    th: 'ลบสถานที่',
    vi: 'Xóa vị trí',
  },
  deleteUser: {
    ja: 'ユーザを削除',
    en: 'Delete User',
    zhcn: '删除用户',
    zhtw: '刪除用戶',
    ko: '사용자 삭제',
    th: 'ลบผู้ใช้',
    vi: 'Xóa người dùng',
  },
  incorrectEmailFormatMessage: {
    ja: 'メールアドレスのフォーマットが正しくありません',
    en: 'Invalid email address format',
    zhcn: 'E-mail地址的格式不正确',
    zhtw: 'E-mail地址的格式不正確',
    ko: '이메일 주소 형식이 잘못되었습니다',
    th: 'รูปแบบที่อยู่อีเมลไม่ถูกต้อง',
    vi: 'Địa chỉ email không đúng định dạng',
  },
  notRegisteredEmailMessage: {
    ja: 'メールアドレスは登録されていません',
    en: 'Email address is not registered',
    zhcn: 'E-mail地址未注册',
    zhtw: 'E-mail地址未註冊',
    ko: '이메일 주소가 등록되어 있지 않습니다',
    th: 'ที่อยู่อีเมลไม่ได้ลงทะเบียน',
    vi: 'Địa chỉ email chưa đăng ký',
  },
  invalidPasswordMessage: {
    ja: '無効なパワードです',
    en: 'Invalid password',
    zhcn: '无效的密码',
    zhtw: '無效的密碼',
    ko: '잘못된 비밀번호입니다',
    th: 'รหัสผ่านไม่ถูกต้อง',
    vi: 'Mật khẩu không hợp lệ',
  },
  addedMessage: {
    ja: '登録が完了しました',
    en: 'Registration completed',
    zhcn: '登记已完成',
    zhtw: '登記已完成',
    ko: '등록이 완료되었습니다',
    th: 'การลงทะเบียนเสร็จสิ้น',
    vi: 'Đăng ký hoàn tất',
  },
  notificationSent: {
    ja: '配信が完了しました',
    en: 'Notification sent',
    zhcn: '交货完成',
    zhtw: '交貨完成',
    ko: '배달이 완료되었습니다',
    th: 'การส่งสำเร็จ',
    vi: 'Giao hàng hoàn tất',
  },
  updatedMessage: {
    ja: '更新が完了しました',
    en: 'Update completed',
    zhcn: '更新已完成',
    zhtw: '更新已完成',
    ko: '업데이트가 완료되었습니다',
    th: 'การอัปเดตเสร็จสิ้น',
    vi: 'Cập nhật hoàn tất',
  },
  deletedMessage: {
    ja: '削除が完了しした',
    en: 'Deletion completed',
    zhcn: '删除已完成',
    zhtw: '刪除已完成',
    ko: '삭제가 완료되었습니다',
    th: 'การลบเสร็จสิ้น',
    vi: 'Xóa hoàn tất',
  },
  addedSerialMessage: {
    ja: '新規個品流通履歴を登録しました',
    en: 'New product distribution record registered',
    zhcn: '新商品的配送履历登录完成',
    zhtw: '新商品的配送紀錄註冊完成',
    ko: '신규 품목별 유통 이력이 등록되었습니다',
    th: 'รายการการจัดส่งสินค้าใหม่ถูกลงทะเบียนเรียบร้อยแล้ว',
    vi: 'Lịch sử phân phối sản phẩm mới đã được đăng ký',
  },
  updatedSerialMessage: {
    ja: '個品流通履歴情報を更新しました',
    en: 'Product distribution record updated',
    zhcn: '更新了个人物品分发历史信息',
    zhtw: '更新了個人物品分發歷史信息',
    ko: '품목별 유통 이력 정보를 갱신했습니다',
    th: 'ข้อมูลประวัติการจัดส่งสินค้าได้รับการปรับปรุงแล้ว',
    vi: 'Lịch sử phân phối sản phẩm đã được cập nhật',
  },
  deletedSerialMessage: {
    ja: '指定の個品流通履歴を削除しました',
    en: 'Product distribution record deleted',
    zhcn: '商品配送履历已删除',
    zhtw: '商品配送紀錄已刪除',
    ko: '해당 품목별 유통 이력을 삭제했습니다',
    th: 'ประวัติการจัดส่งสินค้าถูกลบแล้ว',
    vi: 'Lịch sử phân phối sản phẩm đã được xóa',
  },
  resetSerialMessage: {
    ja: '指定の個品流通履歴のリセットが完了しました',
    en: 'Product distribution record reset was successful',
    zhcn: '指定单个产品的分销历史的重置现已完成',
    zhtw: '指定單個產品的分銷歷史的重置現已完成',
    ko: '해당 품목별 유통 이력 초기화가 완료되었습니다',
    th: 'การรีเซ็ตประวัติการจัดส่งสินค้าได้รับการลบแล้ว',
    vi: 'Đặt lại hồ sơ phân phối sản phẩm đã thành công',
  },
  addedLocationMessage: {
    ja: '新規拠点を登録しました',
    en: 'New location registered',
    zhcn: '新地点登录完成',
    zhtw: '新地點註冊完成',
    ko: '신규 지점이 등록되었습니다',
    th: 'สถานที่ใหม่ถูกลงทะเบียนเรียบร้อยแล้ว',
    vi: 'Vị trí mới đã được đăng ký',
  },
  updatedLocationMessage: {
    ja: '拠点情報を更新しました',
    en: 'Location information updated',
    zhcn: '地点更新',
    zhtw: '地點更新',
    ko: '지점 정보를 갱신했습니다',
    th: 'ข้อมูลสถานที่ได้รับการปรับปรุงแล้ว',
    vi: 'Thông tin vị trí đã được cập nhật',
  },
  deletedLocationMessage: {
    ja: '指定の拠点を削除しました',
    en: 'Location deleted',
    zhcn: '地点已刪除',
    zhtw: '地點已刪除',
    ko: '해당 지점을 삭제했습니다',
    th: 'สถานที่ถูกลบแล้ว',
    vi: 'Vị trí đã được xóa',
  },
  addedBrandMessage: {
    ja: '新規の銘柄を追加しました',
    en: 'New brand registered',
    zhcn: '新增品牌',
    zhtw: '新增品牌',
    ko: '새 브랜드를 추가했습니다',
    th: 'สัญลักษณ์ใหม่ถูกลงทะเบียนเรียบร้อยแล้ว',
    vi: 'Thêm thương hiệu mới đã được đăng ký',
  },
  updatedBrandMessage: {
    ja: '銘柄情報を更新しました',
    en: 'Brand information updated',
    zhcn: '品牌信息更新',
    zhtw: '品牌資訊更新',
    ko: '브랜드 정보를 갱신했습니다',
    th: 'ข้อมูลสัญลักษณ์ได้รับการปรับปรุงแล้ว',
    vi: 'Thông tin thương hiệu đã được cập nhật',
  },
  deletedBrandMessage: {
    ja: '指定の銘柄を削除しました',
    en: 'Brand deleted',
    zhcn: '品牌已刪',
    zhtw: '品牌已刪',
    ko: '해당 브랜드를 삭제했습니다',
    th: 'สัญลักษณ์ถูกลบแล้ว',
    vi: 'Thương hiệu đã được xóa',
  },
  deletedBrand: {
    ja: '銘柄を削除',
    en: 'Delete Brand',
    zhcn: '刪除品牌',
    zhtw: '刪除品牌',
    ko: '브렌드 제거',
    th: 'ลบสัญลักษณ์',
    vi: 'Xóa thương hiệu',
  },
  addedUserMessage: {
    ja: '新規ユーザを登録しました',
    en: 'New user registered',
    zhcn: '新用戶註冊',
    zhtw: '新用戶註冊',
    ko: '새로운 사용자가 등록되었습니다',
    th: 'ผู้ใช้ใหม่ถูกลงทะเบียนเรียบร้อยแล้ว',
    vi: 'Người dùng mới đã được đăng ký',
  },
  updatedUserMessage: {
    ja: 'ユーザ情報を更新しました',
    en: 'User information updated',
    zhcn: '用户信息更新',
    zhtw: '用戶資訊更新',
    ko: '사용자 정보를 갱신했습니다',
    th: 'ข้อมูลผู้ใช้ได้รับการปรับปรุงแล้ว',
    vi: 'Thông tin người dùng đã được cập nhật',
  },
  deletedUserMessage: {
    ja: '指定のユーザを削除しました',
    en: 'User deleted',
    zhcn: '用戶已刪',
    zhtw: '用戶已刪',
    ko: '해당 사용자를 삭제했습니다',
    th: 'ผู้ใช้ถูกลบแล้ว',
    vi: 'Người dùng đã được xóa',
  },
  addedHistoryMessage: {
    ja: '新規の履歴を登録しました',
    en: 'New record registered',
    zhcn: '新履历註冊',
    zhtw: '新履歷登錄',
    ko: '새로운 기록이 등록되었습니다',
    th: 'ประวัติใหม่ถูกลงทะเบียนเรียบร้อยแล้ว',
    vi: 'Lịch sử mới đã được đăng ký',
  },
  updatedHistoryMessage: {
    ja: '履歴情報を更新しました',
    en: 'Record updated',
    zhcn: '履历已更新',
    zhtw: '履歷已更新',
    ko: '이력 정보를 갱신했습니다',
    th: 'ข้อมูลประวัติได้รับการปรับปรุงแล้ว',
    vi: 'Lịch sử đã được cập nhật',
  },
  deletedHistoryMessage: {
    ja: '指定の履歴を削除しました',
    en: 'Record deleted',
    zhcn: '履历已刪',
    zhtw: '履歷已刪',
    ko: '해당 기을 삭제했습니다',
    th: 'ประวัติถูกลบแล้ว',
    vi: 'Lịch sử đã được xóa',
  },
  addedCommentMessage: {
    ja: '新規コメントを登録しました',
    en: 'New comment registered',
    zhcn: '它已注册了新的评论',
    zhtw: '它已註冊了新的評論',
    ko: '새 댓글이 등록되었습니다',
    th: 'ความคิดเห็นใหม่ถูกลงทะเบียนเรียบร้อแล้ว',
    vi: 'Bình luận mới đã được đăng ký',
  },
  updatedCommentMessage: {
    ja: 'コメントを更新しました',
    en: 'Comment updated',
    zhcn: '更新评论',
    zhtw: '更新評論',
    ko: '댓글을 갱신했습니다',
    th: 'ความคิดเห็นได้รับการปรับปรุงแล้ว',
    vi: 'Bình luận đã được cập nhật',
  },
  deletedCommentMessage: {
    ja: '指定のコメントを削除しました',
    en: 'Comment deleted',
    zhcn: '评论已刪除',
    zhtw: '評論已刪除',
    ko: '해당 댓글을 삭제했습니다',
    th: 'ความคิดเห็นถูกลบแล้ว',
    vi: 'Bình luận đã được xóa',
  },
  notFoundMessage: {
    ja: '指定したページは存在しません',
    en: 'This page does not exist.',
    zhcn: '指定的页面不存在',
    zhtw: '指定的頁面不存在',
    ko: '해당 페이지가 존재하지 않습니다',
    th: 'หน้านี้ไม่มีอยู่',
    vi: 'Trang này không tồn tại',
  },
  require: {
    ja: '*必',
    en: '*Required',
    zhcn: '*必需的',
    zhtw: '*必需的',
    ko: '*필수',
    th: '*ต้องการ',
    vi: '*Bắt buộc',
  },
  deleteThis: {
    ja: '削除する',
    en: 'Delete',
    zhcn: '删除',
    zhtw: '刪除',
    ko: '삭제하기',
    th: 'ลบ',
    vi: 'Xóa',
  },
  updateThis: {
    ja: '編集する',
    en: 'Update',
    zhcn: '编辑',
    zhtw: '編輯',
    ko: '수정',
    th: 'แก้ไข',
    vi: 'Cập nhật',
  },
  editHistory: {
    ja: '編集履歴一覧',
    en: 'Editing History',
    zhcn: '编辑历史记录列表',
    zhtw: '編輯歷史記錄列表',
    ko: '수정 이력보기',
    th: 'ประวัติการแก้ไข',
    vi: 'Lịch sử chỉnh sửa',
  },
  openAllSections: {
    ja: '全セクションを開く',
    en: 'Open All Sections',
    zhcn: '打开所有章节',
    zhtw: '打開所有章節',
    ko: '모든 섹션 열기',
    th: 'เปิดทุกส่วน',
    vi: 'Mở tất cả các phần',
  },
  closeAllSections: {
    ja: '全セクションを閉じる',
    en: 'Close All Sections',
    zhcn: '关闭所有章节',
    zhtw: '關閉所有章節',
    ko: '모든 섹션 닫기',
    th: 'ปิดทุกส่วน',
    vi: 'Đóng tất cả các phần',
  },
  customizeTheOrderOfSections: {
    ja: 'セクションの並び順をカスタマイズ',
    en: 'Section Order Customization',
    zhcn: '自定义部分的顺序',
    zhtw: '自定義部分的順序',
    ko: '섹션 순서 커스터마이즈',
    th: 'ประกอบด้วยส่วนที่กำหนดเอง',
    vi: 'Tùy chỉnh thứ tự phần',
  },
  serialDistributionHistories: {
    ja: '個品流通履歴',
    en: 'Product Distribution Record',
    zhcn: '产品发货记录',
    zhtw: '產品發貨紀錄',
    ko: '품목별 유통 이력',
    th: 'ประวัติการจัดส่งสินค้า',
    vi: 'Lịch sử phân phối sản phẩm',
  },
  restaurantShippingConfirmation: {
    ja: 'レストラン出荷確認',
    en: 'Restaurant Shipping Confirmation',
    zhcn: '餐厅发货确认',
    zhtw: '餐廳發貨確認',
    ko: '레스토랑 출하 확인',
    th: 'การยืนยันการจัดส่งร้านอาหาร',
    vi: 'Xác nhận giao hàng nhà hàng',
  },
  restaurantManagement: {
    ja: 'レストラン管理',
    en: 'Restaurant Management',
    zhcn: '餐厅管理',
    zhtw: '餐廳管理',
    ko: '레스토랑 관리',
    th: 'การจัดการร้านอาหาร',
    vi: 'Quản lý nhà hàng',
  },
  consumerScanAnalysis: {
    ja: '消費者スキャン数分析',
    en: 'Consumer Scan Analysis',
    zhcn: '消费者扫描分析',
    zhtw: '消費者掃描分析',
    ko: '소자 스캔 분석',
    th: 'การวิเคราะห์จำนวนการสแกนผู้บริโภค',
    vi: 'Phân tích số lần quét của người tiêu dùng',
  },
  consumerLocationAnalysis: {
    ja: '消費者ロケーション分析',
    en: 'Consumer Location Analysis',
    zhcn: '消费者定位分析',
    zhtw: '消費者定位分析',
    ko: '소비자 위치 분석',
    th: 'การวิเคราะห์ตำแหน่งของผู้บริโภค',
    vi: 'Phân tích vị trí của người tiêu dùng',
  },
  swapTheOrderByDraggingAndDropping: {
    ja: 'ドラッグ＆ドロップで順序を入れ替えてください',
    en: 'Drag & drop to change order',
    zhcn: '请拖动和拖放更换顺序',
    zhtw: '請拖動和拖放更換順序',
    ko: '드래그 앤 드롭으로 순서 변경',
    th: 'ลากและวางเพื่อเปลี่ยนลำดับ',
    vi: 'Kéo và thả để thay đổi thứ tự',
  },
  setToInitialState: {
    ja: '初期状態に戻す',
    en: 'Set to Initial State',
    zhcn: '返回到初始状态',
    zhtw: '返回到初始狀態',
    ko: '원래 상태로 복원',
    th: 'กลับไปยังสถานะเริ่มต้น',
    vi: 'Trở lại trạng thái ban ầu',
  },
  myChats: {
    ja: 'マイチャット',
    en: 'My Chat',
    zhcn: '我的聊天',
    zhtw: '我的聊天',
    ko: '내 채팅',
    th: 'การสนทนาโดยตรงของฉัน',
    vi: 'Cuộc trò chuyện của tôi',
  },
  allUserChats: {
    ja: '全ユーザのチャット',
    en: "All User's Chat",
    zhcn: '聊天的所有用户',
    zhtw: '聊天的所有用戶',
    ko: '모든 사용자의 채팅',
    th: 'การสนทนาของผู้ใช้ทั้งหมด',
    vi: 'Cuộc trò chuyện của tất cả người dùng',
  },
  deleteThisChatRoom: {
    ja: 'このチャットルームを削除する',
    en: 'Delete this chat room',
    zhcn: '删除此聊天室',
    zhtw: '刪除此聊天室',
    ko: '이 채팅방 삭제',
    th: 'ลบห้องสนทนานี้',
    vi: 'Xóa phòng trò chuyện này',
  },
  moveToChatRoom: {
    ja: 'このチャットルームへ移動する',
    en: 'Move to this chat room',
    zhcn: '移动到这个聊天室',
    zhtw: '移動到這個聊天室',
    ko: '이 채팅방으로 이동',
    th: 'เลื่อนไปยังห้องสนทนานี้',
    vi: 'Di chuyển đến phòng trò chuyện này',
  },
  searchUsers: {
    ja: 'ユーザを検索',
    en: 'Search User',
    zhcn: '搜索用户',
    zhtw: '搜索用戶',
    ko: '사용자 검색',
    th: 'ค้นหาผู้ใช้',
    vi: 'Tìm kiếm người dùng',
  },
  enterMessageForPC: {
    ja: 'メッセージを入力...（Shift + Enterキーで送信）',
    en: 'Enter message... (Shift + Enter to send)',
    zhcn: '输入消息...（在Shift发送+ Enter键）',
    zhtw: '輸入消息...（在Shift發送+ Enter鍵）',
    ko: '메시지 입력 (Shift + Enter보내기)',
    th: 'ป้อนข้อความ... (Shift + Enter ส่ง)',
    vi: 'Nhập tin nhắn... (Shift + Enter gửi)',
  },
  enterMessageForMobile: {
    ja: 'メッセージを入力...',
    en: 'Enter message...',
    zhcn: '输入您的留言...',
    zhtw: '輸入您的留言...',
    ko: '메시지를 입력',
    th: 'ป้อนข้อความ...',
    vi: 'Nhập tin nhắn...',
  },
  pleaseSelectUser: {
    ja: 'ユーザを選択してください',
    en: 'Select a User',
    zhcn: '请选择一个用户',
    zhtw: '請選擇一個用戶',
    ko: '사용자를 선택하십시오',
    th: 'เลือกผู้ใช้',
    vi: 'Chọn người dùng',
  },
  serials: {
    ja: '個品流通履歴管理',
    en: 'Product Distribution Record Management',
    zhcn: '个别产品发货史管理',
    zhtw: '個別產品發貨歷史管理',
    ko: '품목별 유통 이력 관리',
    th: 'การจัดการประวัติการจัดส่งสินค้า',
    vi: 'Quản lý lịch sử phân phối sản phẩm',
  },
  serials2: {
    ja: '個品流通履歴一覧',
    en: 'Product Distribution Record',
    zhcn: '个别产品发货历史记录列表',
    zhtw: '個別產品分發發貨記錄列表',
    ko: '품목별 유통 이력 목록',
    th: 'รายการประวัติการจัดส่งสินค้า',
    vi: 'Danh sách lịch sử phân phối sản phẩm',
  },
  locations: {
    ja: '拠点管理',
    en: 'Location Management',
    zhcn: '现场管理',
    zhtw: '現場管理',
    ko: '지점 관리',
    th: 'การจัดการสถานที่',
    vi: 'Quản lý địa điểm',
  },
  locations2: {
    ja: '拠点一覧',
    en: 'Location List',
    zhcn: '办公室',
    zhtw: '辦公室',
    ko: '지점 목록',
    th: 'รายการสถานที่',
    vi: 'Danh sách địa điểm',
  },
  brands: {
    ja: '銘柄管理',
    en: 'Brand Management',
    zhcn: '品牌管理',
    zhtw: '品牌管理',
    ko: '브랜드 관리',
    th: 'การจัดการสัญลักณ์',
    vi: 'Quản lý thương hiệu',
  },
  brands2: {
    ja: '銘柄一覧',
    en: 'Brand List',
    zhcn: '品牌列表',
    zhtw: '品牌列表',
    ko: '브랜드 목록',
    th: 'รายการสัญลักษณ์',
    vi: 'Danh sách thương hiệu',
  },
  users: {
    ja: 'ユーザ管理',
    en: 'User Management',
    zhcn: '用户管理',
    zhtw: '用戶管理',
    ko: '사용자 관리',
    th: 'การจัดการผู้ใช้',
    vi: 'Quản lý người dùng',
  },
  users2: {
    ja: 'ユーザ一覧',
    en: 'User List',
    zhcn: '用户列表',
    zhtw: '用戶列表',
    ko: '사용자 목록',
    th: 'รายการผู้ใช้',
    vi: 'Danh sách người dùng',
  },
  histories: {
    ja: '履歴管理',
    en: 'Record Management',
    zhcn: '历史管理',
    zhtw: '歷史管理',
    ko: '이력 관리',
    th: 'การจัดการประวัติ',
    vi: 'Quản lý lịch sử',
  },
  histories2: {
    ja: '履歴一覧',
    en: 'Record List',
    zhcn: '历史记录列表',
    zhtw: '歷史記錄列表',
    ko: '이력 목록',
    th: 'รายการประวัติ',
    vi: 'Danh sách lịch sử',
  },
  id: {
    ja: 'ID',
    en: 'ID',
    zhcn: 'ID',
    zhtw: 'ID',
    ko: 'ID',
    th: 'ID',
    vi: 'ID',
  },
  publicId: {
    ja: '公開ID',
    en: 'Public ID',
    zhcn: '公共ID',
    zhtw: '公共ID',
    ko: '공개 ID',
    th: 'รหัสสาธารณะ',
    vi: 'ID công khai',
  },
  customerCode: {
    ja: '取引先番号',
    en: 'Customer Number',
    zhcn: '客戶代碼',
    zhtw: '客户代码',
    ko: '고객 코드',
    th: 'รหัสลูกค้า',
    vi: 'Mã khách hàng',
  },
  name: {
    ja: '名前',
    en: 'Name',
    zhcn: '姓',
    zhtw: '姓',
    ko: '이름',
    th: 'ชื่อ',
    vi: 'Tên',
  },
  shopName: {
    ja: '店名',
    en: 'Restaurant Name',
    zhcn: '店铺名称',
    zhtw: '店铺名称',
    ko: '점명',
    th: 'ชื่อร้านอาหาร',
    vi: 'Tên nhà hàng',
  },
  enName: {
    ja: '名前（英語）',
    en: 'Name (English)',
    zhcn: '名称（英文）',
    zhtw: '名稱（英文）',
    ko: '이름 (영문)',
    th: 'ชื่อ (อังกฤษ)',
    vi: 'Tên (Tiếng Anh)',
  },
  cnName: {
    ja: '名前（簡体字）',
    en: 'Name (Simplified Chinese)',
    zhcn: '名称（简体字）',
    zhtw: '名稱（簡體字）',
    ko: '이름 (간체)',
    th: 'ชื่อ (ภาษาจีนสั้น)',
    vi: 'Tên (Tiếng Trung đơn giản)',
  },
  hkName: {
    ja: '名前（繁体字）',
    en: 'Name (Traditional Chinese)',
    zhcn: '名称（繁体字）',
    zhtw: '名稱（繁體字）',
    ko: '이름 (번체)',
    th: 'ชื่อ (ภาษาจีนเต็ม)',
    vi: 'Tên (Tiếng Trung đầy đủ)',
  },
  krName: {
    ja: '名前（韓国語）',
    en: 'Name (Korean)',
    zhcn: '名称（韩文）',
    zhtw: '名稱（韓文）',
    ko: '이름 (한국어)',
    th: 'ชื่อ (ภาษาเกาหลี)',
    vi: 'Tên (Tiếng Hàn)',
  },
  thName: {
    ja: '名前（タイ語）',
    en: 'Name (Thai)',
    zhcn: '名称（泰文）',
    zhtw: '名稱（泰文）',
    ko: '이름 (태국어)',
    th: 'ชื่อ (ภาษาไทย)',
    vi: 'Tên (Tiếng Thái)',
  },
  viName: {
    ja: '名前（ベトナム語）',
    en: 'Name (Vietnamese)',
    zhcn: '名称（越南文）',
    zhtw: '名稱（越南文）',
    ko: '이름 (베트남어)',
    th: 'ชื่อ (ภาษาเวียดนาม)',
    vi: 'Tên (Tiếng Việt)',
  },
  information: {
    ja: '情報',
    en: 'Information',
    zhcn: '信息',
    zhtw: '資訊',
    ko: '정보',
    th: 'ข้อมูล',
    vi: 'Thông tin',
  },
  enInformation: {
    ja: '情報 (英語)',
    en: 'Information (English)',
    zhcn: '信息（英文）',
    zhtw: '資訊（英文）',
    ko: '정보 (영문)',
    th: 'ข้อมูล (ภาษาอังกฤษ)',
    vi: 'Thông tin (Tiếng Anh)',
  },
  cnInformation: {
    ja: '情報 (簡体字)',
    en: 'Information (Simplified Chinese)',
    zhcn: '信息（简体字）',
    zhtw: '資訊（簡體字）',
    ko: '정보 (간체)',
    th: 'ข้อมูล (ภาษาจีนสั้น)',
    vi: 'Thông tin (Tiếng Trung đơn giản)',
  },
  hkInformation: {
    ja: '情報 (繁体字)',
    en: 'Information (Traditional Chinese)',
    zhcn: '信息（繁体字）',
    zhtw: '資訊（繁體字）',
    ko: '정보 (번체)',
    th: 'ข้อมูล (ภาษาจีนเต็ม)',
    vi: 'Thông tin (Tiếng Trung đầy đủ)',
  },
  krInformation: {
    ja: '情報 (韓国語)',
    en: 'Information (Korean)',
    zhcn: '信息（韩国）',
    zhtw: '資訊（韓國）',
    ko: '정보 (한국어)',
    th: 'ข้อมูล (ภาษาเกาหลี)',
    vi: 'Thông tin (Tiếng Hàn)',
  },
  thInformation: {
    ja: '情報 (タイ語)',
    en: 'Information (Thai)',
    zhcn: '信息（泰文）',
    zhtw: '資訊（泰文）',
    ko: '정보 (태국어)',
    th: 'ข้อมูล (ภาษาไทย)',
    vi: 'Thông tin (Tiếng Thái)',
  },
  viInformation: {
    ja: '情報 (ベトナム語)',
    en: 'Information (Vietnamese)',
    zhcn: '信息（越南文）',
    zhtw: '資訊（越南文）',
    ko: '정보 (베트남어)',
    th: 'ข้อมูล (ภาษาเวียดนาม)',
    vi: 'Thông tin (Tiếng Việt)',
  },
  description: {
    ja: '説明',
    en: 'Description',
    zhcn: '说明',
    zhtw: '說明',
    ko: '설명',
    th: 'คำอธิบาย',
    vi: 'Mô tả',
  },
  enDescription: {
    ja: '説明 (英語)',
    en: 'Description (English)',
    zhcn: '说明（英文）',
    zhtw: '說明（英文）',
    ko: '설명 (영문)',
    th: 'คำอธิบาย (ภาษาอังกฤษ)',
    vi: 'Mô tả (Tiếng Anh)',
  },
  cnDescription: {
    ja: '説明 (簡体字)',
    en: 'Description (Simplified Chinese)',
    zhcn: '说明（简体字）',
    zhtw: '說明（簡體字）',
    ko: '설명 (간체)',
    th: 'คำอธิบาย (ภาษาจีนตัวย่อ)',
    vi: 'Mô tả (Tiếng Trung giản thể)',
  },
  hkDescription: {
    ja: '説明 (繁体字)',
    en: 'Description (Traditional Chinese)',
    zhcn: '说明（繁体字）',
    zhtw: '說明（繁體字）',
    ko: '설명 (번체)',
    th: 'คำอธิบาย (ภาษาจีนตัวเต็ม)',
    vi: 'Mô tả (Tiếng Trung phồn thể)',
  },
  krDescription: {
    ja: '説明 (韓国語)',
    en: 'Description (Korean)',
    zhcn: '说明（韩语）',
    zhtw: '說明（韓語）',
    ko: '설명 (한국어)',
    th: 'คำอธิบาย (ภาษาเกาหลี)',
    vi: 'Mô tả (Tiếng Hàn)',
  },
  thDescription: {
    ja: '説明 (タイ語)',
    en: 'Description (Thai)',
    zhcn: '说明（泰语）',
    zhtw: '說明（泰語）',
    ko: '설명 (태국어)',
    th: 'คำอธิบาย (ภาษาไทย)',
    vi: 'Mô tả (Tiếng Thái)',
  },
  viDescription: {
    ja: '説明 (ベトナム語)',
    en: 'Description (Vietnamese)',
    zhcn: '说明（越南语）',
    zhtw: '說明（越南語）',
    ko: '설명 (베트남어)',
    th: 'คำอธิบาย (ภาษาเวียดนาม)',
    vi: 'Mô tả (Tiếng Việt)',
  },
  image: {
    ja: '画像',
    en: 'Image',
    zhcn: '图像',
    zhtw: '圖像',
    ko: '이미지',
    th: 'รูปภาพ',
    vi: 'Hình ảnh',
  },
  imageUrl: {
    ja: '画像URL',
    en: 'Image URL',
    zhcn: '图片网址',
    zhtw: '圖片網址',
    ko: '이미지 URL',
    th: 'ลิงก์รูปภาพ',
    vi: 'Đường dẫn hình ảnh',
  },
  avatarImageUrl: {
    ja: 'アバター画像URL',
    en: 'Avatar Image URL',
    zhcn: '头像图片URL',
    zhtw: '頭像圖片URL',
    ko: '아바타 이미지 URL',
    th: 'ลิงก์รูปภาพของผู้ใช้',
    vi: 'Đường dẫn hình ảnh của người dùng',
  },
  logoImageUrl: {
    ja: 'ロゴ画像URL',
    en: 'Logo Image URL',
    zhcn: '标志图片网址',
    zhtw: '標誌圖片網址',
    ko: '로고 이미지 URL',
    th: 'ลิงก์รูปภาพของผู้ใช้',
    vi: 'Đường dẫn hình ảnh của người dùng',
  },
  createdAt: {
    ja: '作成日',
    en: 'Created at',
    zhcn: '创建日期',
    zhtw: '創建日期',
    ko: '작성일',
    th: 'วันที่สร้าง',
    vi: 'Ngày tạo',
  },
  updatedAt: {
    ja: '更新日',
    en: 'Updated at',
    zhcn: '更新日期',
    zhtw: '更新日期',
    ko: '업데이트 날짜',
    th: 'วันที่อัพเดท',
    vi: 'Ngày cập nhật',
  },
  locationId: {
    ja: '拠点ID',
    en: 'Location ID',
    zhcn: '位置ID',
    zhtw: '位置ID',
    ko: '지점 ID',
    th: 'รหัสสถานที่',
    vi: 'ID vị trí',
  },
  location: {
    ja: '拠点',
    en: 'Location',
    zhcn: '位置',
    zhtw: '位置',
    ko: '지점',
    th: 'สถานที่',
    vi: 'Vị trí',
  },
  belongLocation: {
    ja: '所属拠点',
    en: 'Location',
    zhcn: '所属位置',
    zhtw: '所屬位置',
    ko: '소속 지점',
    th: 'สถานที่ที่อยู่ภายใต้',
    vi: 'Vị trí thuộc',
  },
  cancel: {
    ja: 'キャンセル',
    en: 'Cancel',
    zhcn: '取消',
    zhtw: '取消',
    ko: '취소',
    th: 'ยกเลิก',
    vi: 'Hủy',
  },
  close: {
    ja: '閉じる',
    en: 'Close',
    zhcn: '关闭',
    zhtw: '關閉',
    ko: '닫기',
    th: 'ปิด',
    vi: 'Đóng',
  },
  yes: {
    ja: 'はい',
    en: 'Yes',
    zhcn: '是',
    zhtw: '是',
    ko: '예',
    th: 'ใช่',
    vi: 'Có',
  },
  no: {
    ja: 'いいえ',
    en: 'No',
    zhcn: '否',
    zhtw: '否',
    ko: '아니오',
    th: 'ไม่',
    vi: 'Không',
  },
  set: {
    ja: '設定する',
    en: 'Set',
    zhcn: '设定',
    zhtw: '設定',
    ko: '설정',
    th: 'ตั้งค่า',
    vi: 'Thiết lập',
  },
  editData: {
    ja: 'データ編集',
    en: 'Edit Data',
    zhcn: '数据编辑',
    zhtw: '編輯資料',
    ko: '데이터 수정',
    th: 'แก้ไขข้อมูล',
    vi: 'Chỉnh sửa dữ liệu',
  },
  addNewData: {
    ja: '新規追加',
    en: 'Add New Data',
    zhcn: '新增数据',
    zhtw: '新增資料',
    ko: '새로운 데이터 추가',
    th: 'เพิ่มข้อมูลใหม่',
    vi: 'Thêm dữ liệu mới',
  },
  add: {
    ja: '追加',
    en: 'Add',
    zhcn: '追加',
    zhtw: '追加',
    ko: '추가',
    th: 'เพิ่ม',
    vi: 'Thêm',
  },
  edit: {
    ja: '編集',
    en: 'Edit',
    zhcn: '编辑',
    zhtw: '編輯',
    ko: '수정',
    th: 'แก้ไข',
    vi: 'Chỉnh sửa',
  },
  delete: {
    ja: '削除',
    en: 'Delete',
    zhcn: '删除',
    zhtw: '刪除',
    ko: '삭제',
    th: 'ลบ',
    vi: 'Xóa',
  },
  register: {
    ja: '登録する',
    en: 'Register',
    zhcn: '注册',
    zhtw: '註冊',
    ko: '등록',
    th: 'ลงทะเบียน',
    vi: 'Đăng ký',
  },
  registerShort: {
    ja: '登録',
    en: 'Register',
    zhcn: '注册',
    zhtw: '註冊',
    ko: '등록',
    th: 'ลงทะเบียน',
    vi: 'Đăng ký',
  },
  update: {
    ja: '更新する',
    en: 'Update',
    zhcn: '更新',
    zhtw: '更新',
    ko: '업데이트',
    th: 'อัพเดท',
    vi: 'Cập nhật',
  },
  notify: {
    ja: '送信',
    en: 'Notify',
    zhcn: '传输',
    zhtw: '傳輸',
    ko: '전파',
    th: 'ส่ง',
    vi: 'Gửi',
  },
  rejectMessage: {
    ja: '申請者に差し戻し理由が送信されました',
    en: 'The reason for rejecting has been sent to the distributor.',
    zhcn: '还押理由已发送给申请人',
    zhtw: '還押理由已發送給申請人',
    ko: '송금 사유가 신청자에게 발송되었습니다.',
    th: 'ข้อเสนอรับการส่งคืนมีการส่งไปยังผู้สมัคร',
    vi: 'Lý do từ chối đã được gửi đến người xin vay.',
  },
  imageUpload: {
    ja: 'メイン画像アップロード',
    en: 'Upload Image',
    zhcn: '主要图片上传',
    zhtw: '主要圖片上傳',
    ko: '메인 이미지 업로드',
    th: 'อัปโหลดรูปภาพหลัก',
    vi: 'Tải lên hình ảnh chính',
  },
  avatarImageUpload: {
    ja: 'アバター画像アップロード',
    en: 'Upload Avatar Image',
    zhcn: '头像图片上传',
    zhtw: '頭像圖片上傳',
    ko: '아바타 이미지 업로드',
    th: 'อัปโหลดรูปภาพส่วนตัว',
    vi: 'Tải lên hình ảnh đại diện',
  },
  csvUpload: {
    ja: 'CSVファイルのアップロード',
    en: 'Upload CSV File',
    zhcn: '上传CSV文件',
    zhtw: '上傳CSV文件',
    ko: 'CSV 파일 업로드',
    th: 'อัปโหลดไฟล์ CSV',
    vi: 'Tải lên tệp CSV',
  },
  logoImageUpload: {
    ja: 'ロゴ画像アップロード',
    en: 'Upload Logo Image',
    zhcn: '商标图片上传',
    zhtw: '商標圖片上傳',
    ko: '로고 이미지 업로드',
    th: 'อัปโหลดรูปภาพโลโก้',
    vi: 'Tải lên hình ảnh logo',
  },
  textLabelsBodyNoMatch: {
    ja: 'データが存在しません',
    en: 'No existing data found',
    zhcn: '数据不存在',
    zhtw: '資料不存在',
    ko: '데이터가 존재하지 않습니다',
    th: 'ไม่มีข้อมูลที่มีอยู่',
    vi: 'Không tìm thấy dữ liệu',
  },
  textLabelsBodyToolTip: {
    ja: '並び替え',
    en: 'Sort',
    zhcn: '种类',
    zhtw: '種類',
    ko: '정렬',
    th: 'เรียงลำดับ',
    vi: 'Sắp xếp',
  },
  textLabelsPaginationNext: {
    ja: '次のページ',
    en: 'Next Page',
    zhcn: '下页',
    zhtw: '下',
    ko: '다음 페이지',
    th: 'หน้าถัดไป',
    vi: 'Trang tiếp theo',
  },
  textLabelsPaginationPrevious: {
    ja: '前のページ',
    en: 'Previous Page',
    zhcn: '上一页',
    zhtw: '上一頁',
    ko: '이전 페이지',
    th: 'หน้าก่อนหน้า',
    vi: 'Trang trước',
  },
  textLabelsPaginationRowsPerPage: {
    ja: 'Rows per page:',
    en: 'Rows per page:',
    zhcn: '每页行数：',
    zhtw: '每頁行數：',
    ko: 'Rows per page :',
    th: 'แถวต่อหน้า:',
    vi: 'Số hàng trên mỗi trang:',
  },
  textLabelsPaginationDisplayRows: {
    ja: 'of',
    en: 'of',
    zhcn: '的',
    zhtw: '的',
    ko: 'of',
    th: 'ของ',
    vi: 'của',
  },
  textLabelsToolbarSearch: {
    ja: 'キーワード検索をする',
    en: 'Search',
    zhcn: '关键字搜索',
    zhtw: '關鍵字搜索',
    ko: '키워드 검색',
    th: 'ค้นหาคำสำคัญ',
    vi: 'Tìm kiếm từ khóa',
  },
  textLabelsToolbarDownloadCsv: {
    ja: 'CSVファイルをダウンロードする',
    en: 'Download CSV File',
    zhcn: '下载CSV文件',
    zhtw: '下載CSV文件',
    ko: 'CSV 파일 다운로드',
    th: 'ดาวน์โหลดไฟล์ CSV',
    vi: 'Tải xuống tệp CSV',
  },
  textLabelsToolbarFilterTable: {
    ja: 'フィルター条件を指定する',
    en: 'Select Filtering Condition',
    zhcn: '要指定过滤条件',
    zhtw: '要指定過濾條件',
    ko: '필터 조건 설정',
    th: 'ระบุเงื่อนไขการกรอง',
    vi: 'Chỉ định điều kiện lọc',
  },
  textLabelsToolbarViewColumns: {
    ja: '表示項目をカスタマイズする',
    en: 'Customize Presented Item',
    zhcn: '要自定义显示项目',
    zhtw: '要自定義顯示項目',
    ko: '표시 항목을 커스터마이즈',
    th: 'ประกอบด้วยรายการที่แสดง',
    vi: 'Tùy chỉnh mục được hiển thị',
  },
  textLabelsFilterAll: {
    ja: '全て',
    en: 'All',
    zhcn: '全部',
    zhtw: '全部',
    ko: '모든',
    th: 'ทั้งหมด',
    vi: 'Tất cả',
  },
  textLabelsFilterTitle: {
    ja: 'フィルター条件',
    en: 'Filtering Condition',
    zhcn: '过滤条件',
    zhtw: '過濾條件',
    ko: '필터 조건',
    th: 'เงื่อนไขการกรอง',
    vi: 'iều kiện lọc',
  },
  textLabelsFilterReset: {
    ja: 'リセットする',
    en: 'Reset',
    zhcn: '重置',
    zhtw: '重置',
    ko: '재설정',
    th: 'รีเซ็ต',
    vi: 'Đặt lại',
  },
  reset: {
    ja: 'リセット',
    en: 'Reset',
    zhcn: '重置',
    zhtw: '重置',
    ko: '재설정',
    th: 'รีเซ็ต',
    vi: 'Đặt lại',
  },
  consumerScanHistory: {
    ja: '消費者スキャン履歴',
    en: 'Consumer Scan History',
    zhcn: '消费者扫描历史记录',
    zhtw: '消費者掃描歷史記錄',
    ko: '소비자 검사 기록',
    th: 'ประวัติการสแกนของผู้บริโภค',
    vi: 'Lịch sử quét của người tiêu dùng',
  },
  scanDate: {
    ja: 'スキャン日時',
    en: 'Scanned Time',
    zhcn: '扫描的日期和时间',
    zhtw: '掃描的日期和時間',
    ko: '스캔 날짜',
    th: 'วันที่และเวลาที่สแกน',
    vi: 'Ngày và thời gian quét',
  },
  scanLocation: {
    ja: 'スキャン場所',
    en: 'Scanned Location',
    zhcn: '扫描位置',
    zhtw: '掃描位置',
    ko: '스캔 장소',
    th: 'สแกนตำแหน่ง',
    vi: 'Vị trí quét',
  },
  age: {
    ja: '年齢',
    en: 'Age',
    zhcn: '龄',
    zhtw: '年齡',
    ko: '나이',
    th: 'อายุ',
    vi: 'Tuổi',
  },
  city: {
    ja: '都市',
    en: 'City',
    zhcn: '城市',
    zhtw: '城市',
    ko: '도시',
    th: 'เมือง',
    vi: 'Thành phố',
  },
  timezone: {
    ja: '時間帯',
    en: 'Timezone',
    zhcn: '时区',
    zhtw: '時區',
    ko: '시간',
    th: 'เวลา',
    vi: 'Thời gian',
  },
  gender: {
    ja: '性別',
    en: 'Gender',
    zhcn: '性别',
    zhtw: '性別',
    ko: '성별',
    th: 'เพศ',
    vi: 'Giới tính',
  },
  scanCount: {
    ja: 'スキャン数',
    en: 'Scanned Counts',
    zhcn: '扫描数',
    zhtw: '掃描數',
    ko: '스캔한 수',
    th: 'จำนวนการสแกน',
    vi: 'Số lượng quét',
  },
  editSerial: {
    ja: '個品流通履歴を編集',
    en: 'Edit Product Distribution Record',
    zhcn: '编辑单个产品的分销历史',
    zhtw: '編輯單個產品的分銷歷史',
    ko: '품목별 유통 이력 수정',
    th: 'แก้ไขประวัติการจัดจำหน่ายสินค้า',
    vi: 'Chỉnh sửa lịch sử phân phối sản phẩm',
  },
  addNewSerial: {
    ja: '個品流通履歴を新規追加',
    en: 'Add New Product Distribution Record',
    zhcn: '加新的单独的产品分发历史记录',
    zhtw: '添加新的單獨的產品分發歷史記錄',
    ko: '품목별 유통 이력 새로 추가',
    th: 'เพิ่มประวัติการจัดจำหน่ายสินค้าใหม่',
    vi: 'Thêm lịch sử phân phối sản phẩm mới',
  },
  serialDetail: {
    ja: '個品流通履歴の詳細',
    en: 'Product Distribution Record Detail',
    zhcn: '个别产品分布的历史细节',
    zhtw: '個別產品分佈的歷史細節',
    ko: '품목별 유통 이력 상세',
    th: 'รายละเอียดประวัติการจัดจำหน่ายสินค้า',
    vi: 'Chi tiết lịch sử phân phối sản phẩm',
  },
  basicInformation: {
    ja: '基本情報',
    en: 'Basic Information',
    zhcn: '基本信息',
    zhtw: '基本信息',
    ko: '기본 정보',
    th: 'ข้อมูลพื้นฐาน',
    vi: 'Thông tin cơ bản',
  },
  editSerial2: {
    ja: 'シリアル番号情報を編集',
    en: 'Edit Serial Number Information',
    zhcn: '编辑序列号信息',
    zhtw: '編輯序列號信息',
    ko: '시리얼 번호 정보 수정',
    th: 'แก้ไขข้อมูลหมายเลขซีเรียล',
    vi: 'Chỉnh sửa thông tin số seri',
  },
  addNewSerial2: {
    ja: '新規追加',
    en: 'Add New Serial Number',
    zhcn: '新增序列号',
    zhtw: '新增序列號',
    ko: '신규 추가',
    th: 'เพิ่มรายการใหม่',
    vi: 'Thêm mới',
  },
  serialCode: {
    ja: 'シリアル号',
    en: 'Serial Number',
    zhcn: '序列号',
    zhtw: '序列號',
    ko: '시리얼 번호',
    th: 'หมายเลขซีเรียล',
    vi: 'Số seri',
  },
  serialCode2: {
    ja: 'シリアル',
    en: 'Serial',
    zhcn: '序列',
    zhtw: '序列',
    ko: '시리얼',
    th: 'ซีเรียล',
    vi: 'Số seri',
  },
  brand: {
    ja: '銘柄',
    en: 'Brand',
    zhcn: '品牌',
    zhtw: '品牌',
    ko: '브랜드',
    th: 'ยี่ห้อ',
    vi: 'Nhãn hiệu',
  },
  allBrands: {
    ja: '全ての銘柄',
    en: 'All Brands',
    zhcn: '所有的股票',
    zhtw: '所有的股票',
    ko: '모든 브랜드',
    th: 'ทั้งหมด',
    vi: 'Tất cả',
  },
  brewery: {
    ja: '酒蔵',
    en: 'Brewery',
    zhcn: '酿酒厂',
    zhtw: '釀酒廠',
    ko: '양조장',
    th: 'สถานีสกุล',
    vi: 'Nhà máy',
  },
  breweryName: {
    ja: '酒蔵名',
    en: 'Brewery Name',
    zhcn: '啤酒名',
    zhtw: '啤酒名',
    ko: '양조장 이름',
    th: 'ชื่อสถานีสกุล',
    vi: 'Tên nhà máy',
  },
  breweryPublicId: {
    ja: '酒蔵の公開ID',
    en: 'Brewery Public ID',
    zhcn: '啤酒厂的公共ID',
    zhtw: '啤酒廠的公共ID',
    ko: '양조장 공개 ID',
    th: 'รหัสสาธารณะของสถานีสกุล',
    vi: 'ID công khai của nhà máy',
  },
  adminNote: {
    ja: '備考（管理者用）',
    en: 'Note (Admin)',
    zhcn: '备注（适用于管理员）',
    zhtw: '備註（適用於管理員）',
    ko: '관리자 비고',
    th: 'บันทึก (ผู้ดูแล)',
    vi: 'Ghi chú (Quản trị viên)',
  },
  brewShipAt: {
    ja: '酒蔵出荷日',
    en: 'Brewery Shipping Date',
    zhcn: '装运日期啤酒厂',
    zhtw: '裝運日期啤酒廠',
    ko: '양조장 출하 일',
    th: 'วันที่ส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy',
  },
  brewShipAt2: {
    ja: '酒蔵出荷',
    en: 'Brewery Shipping Date',
    zhcn: '啤酒航运',
    zhtw: '啤酒航運',
    ko: '양조장 출하 일',
    th: 'วันที่ส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy bia',
  },
  brewShipAt3: {
    ja: '出荷日',
    en: 'Brewery Shipping date',
    zhcn: '发货日期',
    zhtw: '發貨日期',
    ko: '양조장 출하 일',
    th: 'วันที่ส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy',
  },
  brewShipScanLatLng: {
    ja: '酒蔵出荷緯度・経度',
    en: 'Longitude/Latitude (Brewery Shipping)',
    zhcn: '啤酒装船纬度和经度',
    zhtw: '啤酒裝船緯度和經度',
    ko: '양조장 하 위도 · 경도',
    th: 'ละติจูด/ลองจิจูด (การส่งออกจากสถานีสกุล)',
    vi: 'Vĩ độ/Kinh độ (Vận chuyển từ nhà máy)',
  },
  brewNote: {
    ja: '酒蔵備考',
    en: 'Note (Brewery)',
    zhcn: '啤酒备注',
    zhtw: '啤酒備註',
    ko: '양조장 비고',
    th: 'บันทึก (สถานีสกุล)',
    vi: 'Ghi chú (Nhà máy)',
  },
  brewShipWarning: {
    ja: '酒蔵出荷警告',
    en: 'Alert (Brewery Shipping)',
    zhcn: '啤酒运警告',
    zhtw: '啤酒運警告',
    ko: '양조장 출하 경고',
    th: 'คำเตือนการส่งออกจากสถานีสกุล',
    vi: 'Cảnh báo vận chuyển từ nhà máy',
  },
  requireSurveyForBrewShip: {
    ja: '酒蔵出荷要調査',
    en: 'Investigation Required (Brewery Shipping)',
    zhcn: '酒厂出货需求调查',
    zhtw: '酒廠出貨需求調查',
    ko: '양조장 출하 요구 조사',
    th: 'ต้องการการสำรวจ (การส่งออกจากสถานีสกุล)',
    vi: 'Yêu cầu khảo sát (Vận chuyển từ nhà máy)',
  },
  brewShipUser: {
    ja: '酒蔵出荷ユーザ',
    en: 'User (Brewery Shipping)',
    zhcn: '啤酒运往用户',
    zhtw: '啤酒運往用戶',
    ko: '양조장 출하 사용자',
    th: 'ผู้ใช้งานการส่งออกจากสถานีสกุล',
    vi: 'Người dùng vận chuyển từ nhà máy',
  },
  brewShipRestLoc: {
    ja: '酒蔵出荷先レストラン',
    en: 'Destination Restaurant (Brewery Shipping)',
    zhcn: '酒厂出货餐厅',
    zhtw: '酒廠出貨餐廳',
    ko: '양조장 출하처 레스토랑',
    th: 'ร้านอาหารปลายทาง (การจัดส่งจากโรงเบียร์)',
    vi: 'Nhà hàng điểm đến (Vận chuyển từ nhà máy bia)',
  },
  distShipWarning: {
    ja: 'ディストリビュータ出荷警告',
    en: 'Alert (Distributor Shipping)',
    zhcn: '经销商出货警告',
    zhtw: '經銷商出貨警告',
    ko: '유통업자 출하 경고',
    th: 'คำเตือนการส่งออกจากสถานีสกุล',
    vi: 'Cảnh báo vận chuyển từ nhà máy',
  },
  checkedDistShipWarning: {
    ja: 'ディストリビュータ出荷警告確認済',
    en: 'Confirmed Alert (Distributor Shipping)',
    zhcn: '经销商出货警告确认',
    zhtw: '經銷商出貨警告確認',
    ko: '유통 출하 경고 확인 완료',
    th: 'คำเตือนการส่งออกจากสถานีสกุลที่ยืนยันแล้ว',
    vi: 'Cảnh báo vận chuyển từ nhà máy đã xác nhận',
  },
  distNote: {
    ja: 'ディストリビュータ備考',
    en: 'Note (Distributor)',
    zhcn: '经销商备注',
    zhtw: '經銷商備註',
    ko: '유통업자 비고',
    th: 'บันทึก (ผู้ดูแล)',
    vi: 'Ghi chú (Quản trị viên)',
  },
  restRecvAt: {
    ja: 'レストラン入荷日',
    en: 'Restaurant Receiving Date',
    zhcn: '餐厅收货日期',
    zhtw: '餐廳收貨日期',
    ko: '레스토랑 입하 일',
    th: 'วันที่สกุลส่งออกจากสถานีสกุล',
    vi: 'Ngày nhận hàng từ nhà máy',
  },
  restRecvAt2: {
    ja: 'レストラン入荷',
    en: 'Restaurant Receiving Date',
    zhcn: '餐厅收货',
    zhtw: '餐廳收貨',
    ko: '레스토랑 입하 일',
    th: 'วันที่สกุลส่งออกจากสถานีสกุล',
    vi: 'Ngày nhận hàng từ nhà máy',
  },
  restRecvScanLatLng: {
    ja: 'レストラン入荷緯度・経度',
    en: 'Longitude/Latitude (Restaurant Receiving)',
    zhcn: '餐厅收货纬度和经度',
    zhtw: '餐廳收貨緯度和經度',
    ko: '레스토랑 입고 위도 · 경도',
    th: 'ละติจูด/ลองจิจูด (การส่งออกจากสถานีสกุล)',
    vi: 'Vĩ độ/Kinh độ (Vận chuyển từ nhà máy)',
  },
  restRecvWarning: {
    ja: 'レストラン入荷警告',
    en: 'Alert (Restaurant Receiving)',
    zhcn: '餐厅收货警告',
    zhtw: '餐廳收貨警告',
    ko: '레스토랑 입하 경고',
    th: 'คำเตือนการรับสินค้าของร้านอาหาร',
    vi: 'Cảnh báo vận chuyển từ nhà máy',
  },
  requireSurveyForRestRecv: {
    ja: 'レストラン入荷要調査',
    en: 'Investigation Required (Restaurant Receiving)',
    zhcn: '餐厅收货調查',
    zhtw: '餐廳收貨調查',
    ko: '레스토랑 입고 조사',
    th: 'การสำรวจที่จำเป็น (การส่งออกจากสถานีสกุล)',
    vi: 'Yêu cầu khảo sát (Vận chuyển từ nhà máy)',
  },
  restRecvUser: {
    ja: 'レストラン入荷ユーザ',
    en: 'User (Restaurant Receiving)',
    zhcn: '餐厅收货用户',
    zhtw: '餐廳收貨用戶',
    ko: '레스토랑 입하 사용자',
    th: 'ผู้ใช้งานการส่งออกจากสถานีสกุล',
    vi: 'Người dùng vận chuyển từ nhà máy',
  },
  checkedRestRecvWarning: {
    ja: 'レストラン入荷警告確認済',
    en: 'Confirmed Alert (Restaurant Receiving)',
    zhcn: '餐厅收货警告确认',
    zhtw: '餐廳收貨警告確認',
    ko: '레스토랑 입하 경고 확인 완료',
    th: 'คำเตือนการส่งออกจากสถานีสกุลที่ยืนยันแล้ว',
    vi: 'Cảnh báo vận chuyển từ nhà máy đã xác nhận',
  },
  restSoldAt: {
    ja: 'レストラン販売日',
    en: 'Restaurant Sales Date',
    zhcn: '餐厅销售日期',
    zhtw: '餐廳銷售日期',
    ko: '레스토랑 판매 날짜',
    th: 'วันที่สกุลส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy',
  },
  soldDate: {
    ja: '販売日',
    en: 'Sales Date',
    zhcn: '发售日期',
    zhtw: '發售日期',
    ko: '판매 일',
    th: 'วันที่สกุลส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy',
  },
  restSoldAt2: {
    ja: 'レストラン販売',
    en: 'Restaurant Sales Date',
    zhcn: '本店销售',
    zhtw: '本店銷售',
    ko: '레스토랑 판매 일',
    th: 'วันที่สกุลส่งออกจากสถานีสกุล',
    vi: 'Ngày vận chuyển từ nhà máy',
  },
  restSoldScanLatLng: {
    ja: 'レストラン販売緯度・経度',
    en: 'Longitude/Latitude (Restaurant Sales)',
    zhcn: '本店销售的纬度和经度',
    zhtw: '本店銷售的緯度和經度',
    ko: '레스토랑 판매 위도 · 경도',
    th: 'ละติจูด/ลองจิจูด (การส่งออกจากสถานีสกุล)',
    vi: 'Vĩ độ/Kinh độ (Vận chuyển từ nhà máy)',
  },
  restSoldWarning: {
    ja: 'レストラン販売警告',
    en: 'Alert (Restaurant Sales)',
    zhcn: '本店销售的警告',
    zhtw: '本店銷售的警告',
    ko: '레스토랑 영업 경고',
    th: 'คำเตือนการส่งออกจากสถานีสกุล',
    vi: 'Cảnh báo vận chuyển từ nhà máy',
  },
  requireSurveyForRestSold: {
    ja: 'レストラン販売要調査',
    en: 'Required Investigation (Restaurant Sales)',
    zhcn: '需要本店销售调查',
    zhtw: '需要本店銷售調查',
    ko: '레스토랑 판매 요구 조사',
    th: 'การสำรวจที่จำเป็น (การส่งออกจากสถานีสกุล)',
    vi: 'Yêu cầu khảo sát (Vận chuyển từ nhà máy)',
  },
  restSoldUser: {
    ja: 'レストラン販売ユーザ',
    en: 'User (Restaurant Sales)',
    zhcn: '本店销售的用户',
    zhtw: '本店銷售的用戶',
    ko: '레스토랑 판매 사용자',
    th: 'ผู้ใช้งานการส่งออกจากสถานีสกุล',
    vi: 'Người dùng vận chuyển từ nhà máy',
  },
  checkedRestSoldWarning: {
    ja: 'レストラン販売警告確認済',
    en: 'Confirmed Alert (Restaurant Sales)',
    zhcn: '店销的警告确认',
    zhtw: '本店銷售的警告確認',
    ko: '레스토랑 영업 경고 확인 완료',
    th: 'คำเตือนการส่งออกจากสถานีสกุลที่ยืนยันแล้ว',
    vi: 'Cảnh báo vận chuyển từ nhà máy đã xác nhận',
  },
  restNote: {
    ja: 'レストラン備考',
    en: 'Note (Restaurant)',
    zhcn: '餐厅备注',
    zhtw: '餐廳備註',
    ko: '레스토랑 비고',
    th: 'บันทึก (ผู้ดูแล)',
    vi: 'Ghi chú (Quản trị viên)',
  },
  distributor: {
    ja: 'ディストリビュータ',
    en: 'Distributor',
    zhcn: '分销商',
    zhtw: '分銷商',
    ko: '유통업자',
    th: 'ผู้จำหน่าย',
    vi: 'Người phân phối',
  },
  restaurant: {
    ja: 'レストラン',
    en: 'Restaurant',
    zhcn: '餐厅',
    zhtw: '餐廳',
    ko: '레스토랑',
    th: 'ร้านอาหาร',
    vi: 'Nhà hàng',
  },
  recvRestLoc: {
    ja: '入荷レストラン',
    en: 'Receiving Restaurant',
    zhcn: '收貨货厅',
    zhtw: '收貨餐廳',
    ko: '입하 레스토랑',
    th: 'ร้านอาหารรับสกุล',
    vi: 'Nhà hàng nhận hàng',
  },
  soldRestLoc: {
    ja: '販売レストラン',
    en: 'Sales Restaurant',
    zhcn: '销售餐厅',
    zhtw: '銷售餐廳',
    ko: '판매 레스토랑',
    th: 'ร้านอาหารขายสกุล',
    vi: 'Nhà hàng bán hàng',
  },
  locationInfo: {
    ja: '位置情報',
    en: 'Location Information(GPS Location)',
    zhcn: '地点信息',
    zhtw: '地點信息',
    ko: '위치 정보',
    th: 'ข้อมูลตำแหน่ง',
    vi: 'Thông tin vị trí',
  },
  baseName: {
    ja: '拠点名',
    en: 'Location Name',
    zhcn: '网站名称',
    zhtw: '網站名稱',
    ko: '지점 이름',
    th: 'ชื่อสถานี',
    vi: 'Tên điểm cơ sở',
  },
  editUser: {
    ja: 'ユーザ情報を編集',
    en: 'Edit User Information',
    zhcn: '编辑用户信息',
    zhtw: '編輯用戶信息',
    ko: '사용자 정보 수정',
    th: 'แก้ไขข้อมูลผู้ใช้',
    vi: 'Chỉnh sửa thông tin người dùng',
  },
  addNewUser: {
    ja: '新規ユーザを追加',
    en: 'Add New User',
    zhcn: '添加新用户',
    zhtw: '添加新用戶',
    ko: '새 사용자 추가',
    th: 'เพิ่มผู้ใช้ใหม่',
    vi: 'Thêm người dùng mới',
  },
  uid: {
    ja: 'ユーザID',
    en: 'User ID',
    zhcn: '用户身份',
    zhtw: '用戶身份',
    ko: '사용자 ID',
    th: 'รหัสผู้ใช้',
    vi: 'ID người dùng',
  },
  password: {
    ja: 'パスワード',
    en: 'Password',
    zhcn: '密码',
    zhtw: '密碼',
    ko: '비밀번호',
    th: 'รหัสผ่าน',
    vi: 'Mật khẩu',
  },
  passwordLogin: {
    ja: 'Password',
    en: 'Password',
    zhcn: '密码',
    zhtw: '密碼',
    ko: '비밀번호',
    th: 'รหัสผ่าน',
    vi: 'Mật khẩu',
  },
  language: {
    ja: '言語',
    en: 'Language',
    zhcn: '语言',
    zhtw: '語言',
    ko: '언어',
    th: 'ภาษา',
    vi: 'Ngôn ngữ',
  },
  company: {
    ja: '会社',
    en: 'Company',
    zhcn: '公司',
    zhtw: '公司',
    ko: '회사',
    th: 'บริษัท',
    vi: 'Công ty',
  },
  email: {
    ja: 'メールアドレス',
    en: 'Email',
    zhcn: '邮件地址',
    zhtw: '郵件地址',
    ko: '이메일 주소',
    th: 'ที่อยู่อีเมล',
    vi: 'Địa chỉ email',
  },
  emailLogin: {
    ja: 'Email Address',
    en: 'Email',
    zhcn: '邮件地址',
    zhtw: '郵件地址',
    ko: '메일 주소',
    th: 'ที่อยู่อีเมล',
    vi: 'Địa chỉ email',
  },
  emailUsage: {
    ja: 'このメールアドレスはログインやこの場所のアラート通知の信に使用されます',
    en:
      'This email address will be used for login and receiving alert notifications for this location.',
    zhcn: '此电子邮件地址将用于登录和接收此位置的警报通知',
    zhtw: '此電子郵件地址將用於登錄和接收此位置的警報通知',
    ko: '이 이메일 주소는 로그인 및 이 위치의 알림 수신에 사용됩니다',
    th: 'ที่อยู่อีเมลนี้จะใช้สำหรับการลงชื่อเข้าใช้และรับการแจ้งเตือนความรุนแรงสำหรับสถานที่นี้',
    vi: 'Địa chỉ email này sẽ được sử dụng để đăng nhập và nhận thông báo cảnh báo cho vị trí này',
  },
  role: {
    ja: '権限',
    en: 'Role',
    zhcn: '权限',
    zhtw: '權限',
    ko: '역할',
    th: 'บทบาท',
    vi: 'Vai trò',
  },
  userBusinessType: {
    ja: '業種',
    en: 'Business Type',
    zhcn: '行业类别',
    zhtw: '行業類別',
    ko: '업종',
    th: 'ประเภทธุรกิ',
    vi: 'Loại hình kinh doanh',
  },
  yearSpan: {
    ja: '年度',
    en: 'Year',
    zhcn: '年',
    zhtw: '年',
    ko: '연도',
    th: 'ปี',
    vi: 'Năm',
  },
  breweryShipmentDistanceDisclaimer: {
    ja: '酒蔵出荷: スキャン位置と登録拠点間の距離',
    en: 'Brewery Shipping: Distance between the scanned location and the registered location',
    zhcn: '酒廠航运：登记和扫描位置基座之间的距离',
    zhtw: '酒廠航運：登記和掃描位置基座之間的距離',
    ko: '양조장 출하 : 스캔 위치와 등록 지점 간의 거리',
    th: 'การส่งออกจากสถานีสกุล : ระยะห่างระหว่างตำแหน่งสแกนและจุดที่ลงทะเบียน',
    vi: 'Vận chuyển từ nhà máy : Khoảng cách giữa vị trí quét và điểm đăng ký',
  },
  restaurantArrivalDisclaimer: {
    ja: 'レストラン入荷: スキャン位置と登録拠点間の距離',
    en: 'Restaurant Receiving: Distance between the scanned location and the registered location',
    zhcn: '餐厅收货：登记和扫描位置基座之间的距离',
    zhtw: '餐廳收貨：登記和掃描位置基座之間的距離',
    ko: '입하 레스토랑 : 스캔 위치와 등록 지점 간의 거',
    th: 'รับสินค้าร้านอาหาร: ระยะห่างระหว่างตำแหน่งสแกนและจุดที่ลงทะเบียน',
    vi: 'Nhận hàng từ nhà hàng : Khoảng cách giữa vị trí quét và điểm đăng ký',
  },
  restaurantSaleDisclaimer: {
    ja: 'レストラン販売: スキャン位置と登録拠点間の距離',
    en: 'Restaurant Sales: Distance between the scanned location and the registered location',
    zhcn: '餐厅销售：登记和扫描位置基座之间的距离',
    zhtw: '餐廳銷售：登記和掃描位置基座之間的距離',
    ko: '판매 레스토랑 : 스캔 위치와 등록 지점 간의 거리',
    th: 'การขายร้านอาหาร : ระยะห่างระหว่างตำแหน่งสแกนและจุดที่ลงทะเบียน',
    vi: 'Bán hàng từ nhà hàng : Khoảng cách giữa vị trí quét và điểm đăng ký',
  },
  selectDateAfterBreweryDateDisclaimer: {
    ja: '酒蔵出荷日以降の日付を選択してください',
    en: 'Select a date after the brewery shipping date',
    zhcn: '请选择啤酒厂出厂日期之日起',
    zhtw: '請選擇啤酒廠出廠日期之日起',
    ko: '양조장 출하 일 이후의 날짜를 선하시요',
    th: 'เลือกวันที่หลังจากวันที่ส่งออกจากสถานีสกุล',
    vi: 'Chọn ngày sau ngày vận chuyển từ nhà máy',
  },
  selectDateAfterRestaurantArrivalDateDisclaimer: {
    ja: 'レストラン入荷日以降の日付を選択してください',
    en: 'Select a date after the restaurant receiving date',
    zhcn: '请选择餐厅收货的日期之后',
    zhtw: '請選擇餐廳收貨的日期之日起',
    ko: '레스토랑 입고 일 이후의 날짜를 선택하시요',
    th: 'เลือกวันที่หลังจากวันที่รับสกุลร้านอาหาร',
    vi: 'Chọn ngày sau ngày nhận hàng từ nhà hàng',
  },
  pleaseRegTheLocBeforeUserReg: {
    ja: 'ユーザ登録の前に、拠点を登録してください',
    en: 'Register a location before user registration',
    zhcn: '在用户登记的前方，请注册地奌',
    zhtw: '在用戶登記的前方，請註冊地點',
    ko: '등록 전에 지점을 등록하십시오',
    th: 'ก่อนที่ผู้ใช้จะลงทะเบียน โปรดลงทะเบียนสถานที่',
    vi: 'Trước khi đăng ký người dùng, vui lòng đăng ký điểm cơ sở',
  },
  pleaseSetTheLocation: {
    ja: '拠点を設定してください',
    en: 'Please set a location',
    zhcn: '请设置地奌',
    zhtw: '請設定地點',
    ko: '기지를 설치하십시오',
    th: 'กรุณาเลือกสถานที่',
    vi: 'Vui lòng chọn điểm cơ sở',
  },
  pleaseSetTheName: {
    ja: '名前を設定してください',
    en: 'Please set a name',
    zhcn: '请设置名称',
    zhtw: '請設置名稱',
    ko: '이름을 설정하십시오',
    th: 'กรุณาใส่ชื่อ',
    vi: 'Vui lòng đặt tên',
  },
  pleaseSetTheEmail: {
    ja: 'メールアドレスを設定してください',
    en: 'Please set an email address',
    zhcn: '请设置电子邮件地址',
    zhtw: '請設置電子郵件地址',
    ko: '이메일 주소를 설정하십시오',
    th: 'กรุณาใส่ที่อยู่อีเมล',
    vi: 'Vui lòng đặt địa chỉ email',
  },
  invalidEmailFormat: {
    ja: 'メールアドレスのフォーマットが正しくありません',
    en: 'Invalid email address format',
    zhcn: 'E-mail格式错误',
    zhtw: 'E-mail格式錯誤',
    ko: '이메일 주소 형식이 잘못되었습니다',
    th: 'รูปแบบที่อยู่อีเมลไม่ถูกต้อง',
    vi: 'Địa chỉ email không đúng định dạng',
  },
  pleaseSetThePassword: {
    ja: 'パスワードを設定してください',
    en: 'Please set a password',
    zhcn: '请设置密码',
    zhtw: '請設置密碼',
    ko: '암호를 설정하십시오',
    th: 'กรุณาใส่รหัสผ่าน',
    vi: 'Vui lòng đặt mật khẩu',
  },
  editLocation: {
    ja: '拠点情報を編集',
    en: 'Edit Customer Information',
    zhcn: '编辑位置信息',
    zhtw: '編輯位置信息',
    ko: '지점 정보 수정',
    th: 'แก้ไขข้อมูลสถานที่',
    vi: 'Chỉnh sửa thông tin điểm cơ sở',
  },
  addNewLocation: {
    ja: '新規拠点を追加',
    en: 'Add New Customer Information',
    zhcn: '添加新地奌',
    zhtw: '添加新地點',
    ko: '신규 지점을 추가',
    th: 'เพิ่มสถานที่ใหม่',
    vi: 'Thêm điểm cơ sở mới',
  },
  latitude: {
    ja: '緯度',
    en: 'Latitude',
    zhcn: '纬度',
    zhtw: '緯度',
    ko: '위도',
    th: 'ละติจูด',
    vi: 'Vĩ độ',
  },
  longitude: {
    ja: '経度',
    en: 'Longitude',
    zhcn: '经度',
    zhtw: '經度',
    ko: '경도',
    th: 'ลองจิจูด',
    vi: 'Kinh độ',
  },
  category: {
    ja: 'カテゴリ',
    en: 'Category',
    zhcn: '类别',
    zhtw: '類別',
    ko: '카테고리',
    th: 'ประเภท',
    vi: 'Loại',
  },
  subCategory: {
    ja: 'サブカテリ',
    en: 'Sub Category',
    zhcn: '子目录',
    zhtw: '子目錄',
    ko: '하위 카테고리',
    th: 'ประเภทย่อย',
    vi: 'Danh mục con',
  },
  locationBusinessType: {
    ja: 'ビジネス種別',
    en: 'Business Type',
    zhcn: '行业类别',
    zhtw: '行業類別',
    ko: '업종',
    th: 'ประเภทธุรกิจ',
    vi: 'Loại hình kinh doanh',
  },
  hiddenSetting: {
    ja: '非表示設定',
    en: 'Hide Setting',
    zhcn: '非显示设置',
    zhtw: '非顯示設置',
    ko: '비표시 설정',
    th: 'การซ่อน設定',
    vi: 'Cài đặt ẩn',
  },
  setToHidden: {
    ja: 'アプリ上で非表示にする',
    en: 'Hide in Application',
    zhcn: '要隐藏的应用',
    zhtw: '要隱藏的應用',
    ko: '앱에서 숨기기',
    th: 'ซ่อนในแอปพลิเคชัน',
    vi: 'Ẩn trong ứng dụng',
  },
  hidden: {
    ja: '非表示',
    en: 'Hidden',
    zhcn: '隐藏',
    zhtw: '隱藏',
    ko: '숨기기',
    th: 'ซ่อน',
    vi: 'Ẩn',
  },
  display: {
    ja: '表示',
    en: 'Display',
    zhcn: '展示',
    zhtw: '展示',
    ko: '보기',
    th: 'แสดง',
    vi: 'Hiển thị',
  },
  enable: {
    ja: '有効',
    en: 'Enable',
    zhcn: '现',
    zhtw: '現行',
    ko: '유효',
    th: 'เปิดใช้งาน',
    vi: 'Bật',
  },
  disable: {
    ja: '無効',
    en: 'Disable',
    zhcn: '无效的',
    zhtw: '無效的',
    ko: '무효',
    th: 'ไม่ใช้งาน',
    vi: 'Vô hiệu',
  },
  supplier: {
    ja: '仕入先',
    en: 'Supplier',
    zhcn: '供应商',
    zhtw: '供應商',
    ko: '공급 업체',
    th: 'ผู้จัดจำหน่าย',
    vi: 'Nhà cung cấp',
  },
  country: {
    ja: '国（地域）',
    en: 'Country（Region）',
    zhcn: '国家（地区）',
    zhtw: '國家（地区）',
    ko: '국가（부위）',
    th: 'ประเทศ（ภูมิภาค）',
    vi: 'Quốc gia (Khu vực)',
  },
  regionUser: {
    ja: '地域／ユーザ',
    en: 'Country/User',
    zhcn: '国家/用户',
    zhtw: '國家/用戶',
    ko: '국가/사용자',
    th: 'ประเทศ/ผู้ใช้',
    vi: 'Quốc gia/Người dùng',
  },
  addrState: {
    ja: '都道府県(State/Province/Region)',
    en: 'State/Province/Region',
    zhcn: '省/自治区/直辖市(State/Province/Region)',
    zhtw: '省/自治区/直辖市(State/Province/Region)',
    ko: '도/특별시/광역시(State/Province/Region)',
    th: 'จังหวัด (รัฐ/จังหวัด/ภูมิภาค)',
    vi: 'Tỉnh/Thành phố/Vùng miền (State/Province/Region)',
  },
  addrCity: {
    ja: '市区町村',
    en: 'City',
    zhcn: '市/地区/县/区',
    zhtw: '市/地区',
    ko: '시/군/구',
    th: 'เมือง/อำเภอ/ตำบล',
    vi: 'Thành phố/Quận/Huyện/Xã',
  },
  addrBlock: {
    ja: '区画（〇町〇丁目など）',
    en: 'Street Address',
    zhcn: '乡/镇/街道办事处',
    zhtw: '区/鎮/市街村',
    ko: '면/동/리',
    th: 'อำเภอ/ตำบล/หมู่บ้าน',
    vi: 'Phường/Xã/Thị trấn',
  },
  addrStreetNum: {
    ja: '番地',
    en: 'Street Number',
    zhcn: '村/社区',
    zhtw: '村/里/番地',
    ko: '번지',
    th: 'หมู่บ้าน/ตำบล/หมู่ที่',
    vi: 'Phường/Xã/Thị trấn',
  },
  addrBuilding: {
    ja: '建物名/部屋番号(任意)',
    en: 'Building name/Room number(option)',
    zhcn: '楼号/门牌号码(任意)',
    zhtw: '号/樓/室(任意)',
    ko: '건물명/호수(선택적)',
    th: 'ชื่ออาคาร/ห้องมายเลข(ไม่จำเป็น)',
    vi: 'Tên tòa nhà/Phòng số (tùy chọn)',
  },
  applyToMap: {
    ja: '住所をMapに反映',
    en: 'Apply your address to map',
    zhcn: '将地址反映在地图上',
    zhtw: '将地址显现在地图上',
    ko: '주소를 지도에 표시하기',
    th: 'Apply your address to map',
    vi: 'Apply your address to map',
  },
  mapAddress: {
    ja: '住所 [Googleマップ用]',
    en: 'GoogleMap Address',
    zhcn: '地址[为谷歌地图]',
    zhtw: '地址[為谷歌地圖]',
    ko: '주소 [Google지도 용]',
    th: 'ที่อยู่ [สำหรับ Google แผนที่]',
    vi: 'Địa chỉ [Dành cho Google Map]',
  },
  useMapAddress: {
    ja: '住所 [表示用] と住所 [Googleマップ用] を同一にする',
    en: 'Delivery Address and GoogleMap Address need to be same',
    zhcn: '地址[显示]，以及同一地址的地图展示[为谷歌地图]',
    zhtw: '地址[顯示]，以及同一地址的地圖展示[為谷歌地圖]',
    ko: '주소[표시용]와 주소 [Google지도 용]는 동일 해야함',
    th: 'ที่อยู่ [สำหรับแสดง] และที่อยู่ [สำหรับ Google แผนที่] ต้องเหมือนกัน',
    vi: 'Địa chỉ [Dành cho hiển thị] và Địa chỉ [Dành cho Google Map] phải giống nhau',
  },
  mainAddress: {
    ja: '住所 [表示用]',
    en: 'Delivery Address',
    zhcn: '地址[显示]',
    zhtw: '地址[顯示]',
    ko: '주소 [표시용]',
    th: 'ที่อยู่ [แสดง]',
    vi: 'Địa chỉ [Dành cho hiển thị]',
  },
  enMainAddress: {
    ja: '住所（英語）',
    en: 'Address (English)',
    zhcn: '地址（英文）',
    zhtw: '地址（英文）',
    ko: '주소 (영어)',
    th: 'ที่อยู่ (ภาษาอังกฤษ)',
    vi: 'Địa chỉ (Tiếng Anh)',
  },
  cnMainAddress: {
    ja: '住所（簡体字）',
    en: 'Address (Simplified Chinese)',
    zhcn: '地址（简体字）',
    zhtw: '地址（簡體字）',
    ko: '주소 (간체)',
    th: 'ที่อยู่ (ภาษาจีนตัวย่อ)',
    vi: 'Địa chỉ (Tiếng Trung giản thể)',
  },
  hkMainAddress: {
    ja: '住所（繁体字）',
    en: 'Address (Traditional Chinese)',
    zhcn: '地址（繁体字）',
    zhtw: '地址（繁體字）',
    ko: '주소 (번체)',
    th: 'ที่อยู่ (ภาษาจีนตัวเต็ม)',
    vi: 'Địa chỉ (Tiếng Trung phồn thể)',
  },
  krMainAddress: {
    ja: '住所（韓国語）',
    en: 'Address (South Korea)',
    zhcn: '地址（韩国）',
    zhtw: '地址（韓國）',
    ko: '주소 (한국)',
    th: 'ที่อยู่ (เกาหลีใต้)',
    vi: 'Địa chỉ (Hàn Quốc)',
  },
  thMainAddress: {
    ja: '住所（タイ語）',
    en: 'Address (Thailand)',
    zhcn: '地址（泰国）',
    zhtw: '地址（泰國）',
    ko: '주소 (태국)',
    th: 'ที่อยู่ (ประเทศไทย)',
    vi: 'Địa chỉ (Thái Lan)',
  },
  viMainAddress: {
    ja: '住所（ベトナム語）',
    en: 'Address (Vietnam)',
    zhcn: '地址（越南）',
    zhtw: '地址（越南）',
    ko: '주소 (베트남)',
    th: 'ที่อยู่ (เวียดนาม)',
    vi: 'Địa chỉ (Việt Nam)',
  },
  allowableRange: {
    ja: '誤差許容範囲',
    en: 'GPS Location Range',
    zhcn: '允许误差范围',
    zhtw: '允許誤差範圍',
    ko: '오차 허용 범위',
    th: 'ช่วงความคลาดเคลื่อนที่ยอมรับได้',
    vi: 'Phạm vi sai số được chấp nhận',
  },
  allowableRange2: {
    ja: 'スキャン位置と登録位置との誤差許容範囲',
    en: 'Default GPS range(m)',
    zhcn: '扫描位置和登记位置的误差容许范围',
    zhtw: '掃描位置和登記位置的誤差容許範圍',
    ko: '스캔 위치와 등록 위치와의 오차 허용 범위',
    th: 'ช่วงความคลาดเคลื่อนที่ยอมรับได้',
    vi: 'Phạm vi sai số được chấp nhận',
  },
  supplierId: {
    ja: '仕入先ID',
    en: 'Supplier ID',
    zhcn: '供应商ID',
    zhtw: '供應商ID',
    ko: '공급 업체 ID',
    th: 'รหัสผู้จัดจำหน่าย',
    vi: 'ID Nhà cung cấp',
  },
  editBrand: {
    ja: '銘柄を編集',
    en: 'Edit Brand',
    zhcn: '编辑品牌',
    zhtw: '編輯品牌',
    ko: '브랜드 수정',
    th: 'แก้ไขสัญลักษณ์',
    vi: 'Chỉnh sửa nhãn hiệu',
  },
  brandUrl: {
    ja: '銘柄URL',
    en: 'Brand URL',
    zhcn: '品牌网址',
    zhtw: '品牌網址',
    ko: '브랜드 URL',
    th: 'URL ของแบรนด์',
    vi: 'URL Thương hiệu',
  },
  intro: {
    ja: '紹介',
    en: 'Introduction',
    zhcn: '介绍',
    zhtw: '介紹',
    ko: '소개',
    th: 'คำอธิบาย',
    vi: 'Giới thiệu',
  },
  enIntro: {
    ja: '紹介（英語）',
    en: 'Introduction (English)',
    zhcn: '介绍（英文）',
    zhtw: '介紹（英文）',
    ko: '소개 (영문)',
    th: 'คำอธิบาย (ภาษาอังกฤษ)',
    vi: 'Giới thiệu (Tiếng Anh)',
  },
  cnIntro: {
    ja: '紹介（簡体字）',
    en: 'Introduction (Simplified Chinese)',
    zhcn: '介绍（简体字）',
    zhtw: '介紹（簡體字）',
    ko: '소개 (간체)',
    th: 'คำอธิบาย (ภาษาจีนสำหรับการนำเสนอ)',
    vi: 'Giới thiệu (Tiếng Trung đơn giản)',
  },
  hkIntro: {
    ja: '紹介（繁体字）',
    en: 'Introduction (Traditional Chinese)',
    zhcn: '介绍（繁体字）',
    zhtw: '介紹（繁體字）',
    ko: '소개 (번체)',
    th: 'คำอธิบาย (ภาษาจีนสำหรับการนำเสนอ)',
    vi: 'Giới thiệu (Tiếng Trung truyền thống)',
  },
  krIntro: {
    ja: '紹介（韓国語）',
    en: 'Introduction (Korean)',
    zhcn: '介绍（韩语）',
    zhtw: '介紹（韓語）',
    ko: '소개 (한국어)',
    th: 'คำอธิบาย (ภาษาจีนสำหรับการนำเสนอ)',
    vi: 'Giới thiệu (Tiếng Trung đơn giản)',
  },
  thIntro: {
    ja: '紹介（タイ語）',
    en: 'Introduction (Thai)',
    zhcn: '介绍（泰语）',
    zhtw: '介紹（泰語）',
    ko: '소개 (태국어)',
    th: 'บทนำ (ภาษาไทย)',
    vi: 'Giới thiệu (Tiếng Thái)',
  },
  viIntro: {
    ja: '紹介（ベトナム語）',
    en: 'Introduction (Vietnamese)',
    zhcn: '介绍（越南语）',
    zhtw: '介紹（越南語）',
    ko: '소개 (베트남어)',
    th: 'บทนำ (ภาษาเวียดนาม)',
    vi: 'Giới thiệu (Tiếng Việt)',
  },
  tastingNote1: {
    ja: '試飲情報 [1]',
    en: 'Tasting Note [1]',
    zhcn: '品尝信息[1]',
    zhtw: '品嚐信息[1]',
    ko: '시음 정보 [1]',
    th: 'ข้อมูลการชิม [1]',
    vi: 'Ghi chú thử nếm [1]',
  },
  enTastingNote1: {
    ja: '試飲情報 [1] (英語)',
    en: 'Tasting Note [1] (English)',
    zhcn: '品尝信息[1]（英国）',
    zhtw: '品嚐信息[1]（英國）',
    ko: '시음 정보 [1] (영문)',
    th: 'ข้อมูลการชิม [1] (ภาษาอังกฤษ)',
    vi: 'Thông tin nếm thử [1] (Tiếng Anh)',
  },
  cnTastingNote1: {
    ja: '試飲情報 [1] (簡体字)',
    en: 'Tasting Note [1] (Simplified Chinese)',
    zhcn: '品尝信息[1]（简体字）',
    zhtw: '品嚐信息[1]（簡體字）',
    ko: '시음 정보 [1] (간체)',
    th: 'ข้อมูลการชิม [1] (ภาษาจีนตัวย่อ)',
    vi: 'Thông tin nếm thử [1] (Tiếng Trung giản thể)',
  },
  hkTastingNote1: {
    ja: '試飲情報 [1] (繁体字)',
    en: 'Tasting Note [1] (Traditional Chinese)',
    zhcn: '品酒信息[1]（繁体字）',
    zhtw: '品酒信息[1]（繁體字）',
    ko: '시음 정보 [1] (번체)',
    th: 'ข้อมูลการชิม [1] (ภาษาจีนตัวเต็ม)',
    vi: 'Thông tin nếm thử [1] (Tiếng Trung phồn thể)',
  },
  krTastingNote1: {
    ja: '試飲情報 [1] (韓国語)',
    en: 'Tasting Note [1] (Korean)',
    zhcn: '品尝信息[1]（韩语）',
    zhtw: '品嚐信息[1]（韓語）',
    ko: '시음 정보 [1] (한국어)',
    th: 'ข้อมูลการชิม [1] (ภาษาเกาหลี)',
    vi: 'Thông tin nếm thử [1] (Tiếng Hàn)',
  },
  thTastingNote1: {
    ja: '試飲情報 [1] (タイ語)',
    en: 'Tasting Note [1] (Thai)',
    zhcn: '品尝信息[1]（泰语）',
    zhtw: '品嚐信息[1]（泰語）',
    ko: '시음 정보 [1] (태국어)',
    th: 'ข้อมูลการชิม [1] (ภาษาไทย)',
    vi: 'Thông tin nếm thử [1] (Tiếng Thái)',
  },
  viTastingNote1: {
    ja: '試飲情報 [1] (ベトナム語)',
    en: 'Tasting Note [1] (Vietnamese)',
    zhcn: '品尝信息[1]（越南语）',
    zhtw: '品嚐信息[1]（越南語）',
    ko: '시음 정보 [1] (베트남어)',
    th: 'ข้อมูลการชิม [1] (ภาษาเวียดนาม)',
    vi: 'Thông tin nếm thử [1] (Tiếng Việt)',
  },
  tastingNote2: {
    ja: '試飲情報 [2]',
    en: 'Tasting Note [2]',
    zhcn: '品尝信息[2]',
    zhtw: '品嚐信息[2]',
    ko: '시음 정보 [2]',
    th: 'ข้อมูลการชิม [2]',
    vi: 'Thông tin nếm thử [2]',
  },
  enTastingNote2: {
    ja: '試飲情報 [2] (英語)',
    en: 'Tasting Note [2] (English)',
    zhcn: '品尝信息[2]（英国）',
    zhtw: '品嚐信息[2]（英國）',
    ko: '시음 정보 [2] (영문)',
    th: 'ข้อมูลการชิม [2] (ภาษาอังกฤษ)',
    vi: 'Thông tin nếm thử [2] (Tiếng Anh)',
  },
  cnTastingNote2: {
    ja: '試飲情報 [2] (簡体字)',
    en: 'Tasting Note [2] (Simplified Chinese)',
    zhcn: '品尝信息[2]（简体字）',
    zhtw: '品嚐信息[2]（簡體字）',
    ko: '시음 정보 [2] (간체)',
    th: 'ข้อมูลการชิม [2] (ภาษาจีนตัวย่อ)',
    vi: 'Thông tin nếm thử [2] (Tiếng Trung giản thể)',
  },
  hkTastingNote2: {
    ja: '試飲情報 [2] (繁体字)',
    en: 'Tasting Note [2] (Traditional Chinese)',
    zhcn: '品酒信息[2]（繁体字）',
    zhtw: '品酒信息[2]（繁體字）',
    ko: '시음 정보 [2] (번체)',
    th: 'ข้อมูลการชิม [2] (ภาษาจีนตัวเต็ม)',
    vi: 'Thông tin nếm thử [2] (Tiếng Trung phồn thể)',
  },
  krTastingNote2: {
    ja: '試飲情報 [2] (韓国語)',
    en: 'Tasting Note [2] (Korean)',
    zhcn: '品尝信息[2]（韩语）',
    zhtw: '品嚐信息[2]（韓語）',
    ko: '시음 정보 [2] (한국어)',
    th: 'ข้อมูลการชิม [2] (ภาษาเกาหลี)',
    vi: 'Thông tin nếm thử [2] (Tiếng Hàn)',
  },
  thTastingNote2: {
    ja: '試飲情報 [2] (タイ語)',
    en: 'Tasting Note [2] (Thai)',
    zhcn: '品尝信息[2]（泰语）',
    zhtw: '品嚐信息[2]（泰語）',
    ko: '시음 정보 [2] (태국어)',
    th: 'ข้อมูลการชิม [2] (ภาษาไทย)',
    vi: 'Thông tin nếm thử [2] (Tiếng Thái)',
  },
  viTastingNote2: {
    ja: '試飲情報 [2] (ベトナム語)',
    en: 'Tasting Note [2] (Vietnamese)',
    zhcn: '品尝信息[2]（越南语）',
    zhtw: '品嚐信息[2]（越南語）',
    ko: '시음 정보 [2] (베트남어)',
    th: 'ข้อมูลการชิม [2] (ภาษาเวียดนาม)',
    vi: 'Thông tin nếm thử [2] (Tiếng Việt)',
  },
  paringFood: {
    ja: 'ペアリングフード',
    en: 'Paring Food',
    zhcn: '配对食物',
    zhtw: '配對食物',
    ko: '페어링 푸드',
    th: 'อาหารที่เหมาะกับการเลี้ยง',
    vi: 'Món ăn phối hợp',
  },
  enParingFood: {
    ja: 'ペアリングフード (英語)',
    en: 'Paring Food (English)',
    zhcn: '配对食品（英语）',
    zhtw: '配對食品（英語）',
    ko: '페어링 푸드 (영문)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาอังกฤษ)',
    vi: 'Món ăn phối hợp (Tiếng Anh)',
  },
  cnParingFood: {
    ja: 'ペアリングフード (簡体字)',
    en: 'Paring Food (Simplified Chinese)',
    zhcn: '配对食品（简体字）',
    zhtw: '配對食品（簡體字）',
    ko: '페어링 푸드 (간체)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาจีนตัวย่อ)',
    vi: 'Món ăn phối hợp (Tiếng Trung giản thể)',
  },
  hkParingFood: {
    ja: 'ペアリングフード (繁体字)',
    en: 'Paring Food (Traditional Chinese)',
    zhcn: '配对食品（繁体字）',
    zhtw: '配對食品（繁體字）',
    ko: '페어링 푸드 (번체)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาจีนตัวเต็ม)',
    vi: 'Món ăn phối hợp (Tiếng Trung phồn thể)',
  },
  krParingFood: {
    ja: 'ペアリングフード (韓国語)',
    en: 'Paring Food (Korean)',
    zhcn: '配对食物（韩文）',
    zhtw: '配對食物（韓文）',
    ko: '페어링 푸드 (한국어)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาเกาหลี)',
    vi: 'Món ăn phối hợp (Tiếng Hàn)',
  },
  thParingFood: {
    ja: 'ペアリングフード (タイ語)',
    en: 'Paring Food (Thai)',
    zhcn: '配对食物（泰文）',
    zhtw: '配對食物（泰文）',
    ko: '페어링 푸드 (태국어)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาไทย)',
    vi: 'Món ăn kèm (Tiếng Thái)',
  },
  viParingFood: {
    ja: 'ペアリングフード (ベトナム語)',
    en: 'Paring Food (Vietnamese)',
    zhcn: '配对食物（越南文）',
    zhtw: '配對食物（越南語）',
    ko: '페어링 푸드 (베트남어)',
    th: 'อาหารที่เหมาะกับการเลี้ยง (ภาษาเวียดนาม)',
    vi: 'Món ăn kèm (Tiếng Việt)',
  },
  servingSuggestion: {
    ja: 'おすすめの飲み方',
    en: 'Serving Suggestion',
    zhcn: '推荐的品尝方法',
    zhtw: '推薦的品嘗方法',
    ko: '마시는 방법 추천',
    th: 'คำแนะนำในการดื่ม',
    vi: 'Gợi ý cách thưởng thức',
  },
  enServingSuggestion: {
    ja: 'おすすめの飲み方 (英語)',
    en: 'Serving Suggestion (English)',
    zhcn: '推荐的品尝方法（英文）',
    zhtw: '推薦的品嘗方法（英文）',
    ko: '마시는 방법 추천 (영문)',
    th: 'คำแนะนำในการดื่ม (ภาษาอังกฤษ)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Anh)',
  },
  cnServingSuggestion: {
    ja: 'おすすめの飲み方 (簡体字)',
    en: 'Serving Suggestion (Simplified Chinese)',
    zhcn: '推荐的品尝方法（简体字）',
    zhtw: '推薦的品嘗方法（簡體字）',
    ko: '마시는 방법 추천 (간체)',
    th: 'คำแนะนำในการดื่ม (ภาษาจีนตัวย่อ)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Trung giản thể)',
  },
  hkServingSuggestion: {
    ja: 'おすすめの飲み方 (繁体字)',
    en: 'Serving Suggestion (Traditional Chinese)',
    zhcn: '推荐的品尝方法（繁体字）',
    zhtw: '推薦的品嘗方法（繁體字）',
    ko: '마시는 방법 추천 (번체)',
    th: 'คำแนะนำในการดื่ม (ภาษาจีนตัวเต็ม)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Trung phồn thể)',
  },
  krServingSuggestion: {
    ja: 'おすすめの飲み方 (韓国語)',
    en: 'Serving Suggestion (Korean)',
    zhcn: '推荐的品尝方法（韩文）',
    zhtw: '推薦的品嘗方法（韓文）',
    ko: '마시는 방법 추천 (한국어)',
    th: 'คำแนะนำในการดื่ม (ภาษาเกาหลี)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Hàn)',
  },
  thServingSuggestion: {
    ja: 'おすすめの飲み方 (タイ語)',
    en: 'Serving Suggestion (Thai)',
    zhcn: '推荐的品尝方法（泰文）',
    zhtw: '推薦的品嘗方法（泰文）',
    ko: '마시는 방법 추천 (태국어)',
    th: 'คำแนะนำในการดื่ม (ภาษาไทย)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Thái)',
  },
  viServingSuggestion: {
    ja: 'おすすめの飲み方 (ベトナム語)',
    en: 'Serving Suggestion (Vietnamese)',
    zhcn: '推荐的品尝方法（越南文）',
    zhtw: '推薦的品嘗方法（越南語）',
    ko: '마시는 방법 추천 (베트남어)',
    th: 'คำแนะนำในการดื่ม (ภาษาเวียดนาม)',
    vi: 'Gợi ý cách thưởng thức (Tiếng Việt)',
  },
  riceSpecific: {
    ja: '酒米',
    en: 'Sake Ingredients Rice',
    zhcn: '清酒大米',
    zhtw: '清酒米種',
    ko: '주미',
    th: 'ข้าวของเหล้า',
    vi: 'Gạo cho rượu sake',
  },
  enRiceSpecific: {
    ja: '酒米 (英語)',
    en: 'Sake Ingredients Rice (English)',
    zhcn: '酿酒米（英文）',
    zhtw: '釀酒米種（英文）',
    ko: '주미 (영문)',
    th: 'ข้าวของเหล้า (ภาษาอังกฤษ)',
    vi: 'Gạo cho rượu sake (Tiếng Anh)',
  },
  cnRiceSpecific: {
    ja: '酒米 (簡体字)',
    en: 'Sake Ingredients Rice (Simplified Chinese)',
    zhcn: '酿酒大米（简体字）',
    zhtw: '釀酒米種（簡體字）',
    ko: '주미 (간체)',
    th: 'ข้าวของเหล้า (ภาษาจีนตัวย่อ)',
    vi: 'Gạo cho rượu sake (Tiếng Trung giản thể)',
  },
  hkRiceSpecific: {
    ja: '酒米 (繁体字)',
    en: 'Sake Ingredients Rice (Traditional Chinese)',
    zhcn: '酿酒大米（繁体字）',
    zhtw: '釀酒米種（繁體字）',
    ko: '주미 (번체)',
    th: 'ข้าวของเหล้า (ภาษาจีนตัวเต็ม)',
    vi: 'Gạo cho rượu sake (Tiếng Trung phồn thể)',
  },
  krRiceSpecific: {
    ja: '酒米 (韓国語)',
    en: 'Sake Ingredients Rice (Korean)',
    zhcn: '酿酒米（韩国）',
    zhtw: '釀酒米（韓國）',
    ko: '주미 (한국어)',
    th: 'ข้าวของเหล้า (ภาษาเกาหลี)',
    vi: 'Gạo cho rượu sake (Tiếng Hàn)',
  },
  thRiceSpecific: {
    ja: '酒米 (タイ語)',
    en: 'Sake Ingredients Rice (Thai)',
    zhcn: '酿酒米（泰国）',
    zhtw: '釀酒米（泰國）',
    ko: '주미 (태국어)',
    th: 'ข้าวของเหล้า (ภาษาไทย)',
    vi: 'Gạo cho rượu sake (Tiếng Thái)',
  },
  viRiceSpecific: {
    ja: '酒米 (ベトナム語)',
    en: 'Sake Ingredients Rice (Vietnamese)',
    zhcn: '酿酒米（越南）',
    zhtw: '釀酒米（越南）',
    ko: '주미 (베트남어)',
    th: 'ข้าวของเหล้า (ภาษาเวียดนาม)',
    vi: 'Gạo cho rượu sake (Tiếng Việt)',
  },
  bottleSize: {
    ja: 'ボトルサイズ',
    en: 'Bottle Size',
    zhcn: '瓶大小',
    zhtw: '瓶大小',
    ko: '병 크기',
    th: 'ขนาดขวด',
    vi: 'Kích thước chai',
  },
  enBottleSize: {
    ja: 'ボトルサイズ (英語)',
    en: 'Bottle Size (English)',
    zhcn: '瓶大小（英文）',
    zhtw: '瓶大小（英文）',
    ko: '병 크기 (영문)',
    th: 'ขนาดขวด (ภาษาอังกฤษ)',
    vi: 'Kích thước chai (Tiếng Anh)',
  },
  cnBottleSize: {
    ja: 'ボトルサイズ (簡体字)',
    en: 'Bottle Size (Simplified Chinese)',
    zhcn: '瓶大小（简体字）',
    zhtw: '瓶大小（簡體字）',
    ko: '병 크기 (간체)',
    th: 'ขนาดขวด (ภาษาจีนตัวย่อ)',
    vi: 'Kích thước chai (Tiếng Trung giản thể)',
  },
  hkBottleSize: {
    ja: 'ボトルサイズ (繁体字)',
    en: 'Bottle Size (Traditional Chinese)',
    zhcn: '瓶大小（繁体字）',
    zhtw: '瓶大小（繁體字）',
    ko: '병 크기 (번체)',
    th: 'ขนาดขวด (ภาษาจีนตัวเต็ม)',
    vi: 'Kích thước chai (Tiếng Trung phồn thể)',
  },
  krBottleSize: {
    ja: 'ボトルサイズ (韓国語)',
    en: 'Bottle Size (Korean)',
    zhcn: '瓶大小（韩文）',
    zhtw: '瓶大小（韓文）',
    ko: '병 크기 (한국어)',
    th: 'ขนาดขวด (ภาษาเกาหลี)',
    vi: 'Kích thước chai (Tiếng Hàn)',
  },
  thBottleSize: {
    ja: 'ボトルサイズ (タイ語)',
    en: 'Bottle Size (Thai)',
    zhcn: '瓶大小（泰文）',
    zhtw: '瓶大小（泰文）',
    ko: '병 크기 (태국어)',
    th: 'ขนาดขวด (ภาษาไทย)',
    vi: 'Kích thước chai (Tiếng Thái)',
  },
  viBottleSize: {
    ja: 'ボトルサイズ (ベトナム語)',
    en: 'Bottle Size (Vietnamese)',
    zhcn: '瓶大小（越南文）',
    zhtw: '瓶大小（越南文）',
    ko: '병 크기 (베트남어)',
    th: 'ขนาดขวด (ภาษาเวียดนาม)',
    vi: 'Kích thước chai (Tiếng Việt)',
  },
  alcohol: {
    ja: 'アルコール度数',
    en: 'Alcohol Content',
    zhcn: '酒精度',
    zhtw: '酒精度',
    ko: '알코올 도수',
    th: 'ปริมาณแอลกอฮอล์',
    vi: 'Nồng độ cồn',
  },
  enAlcohol: {
    ja: 'アルコール度数 (英語)',
    en: 'Alcohol Content (English)',
    zhcn: '酒精（英文）',
    zhtw: '酒精（英文）',
    ko: '알코올 도수 (영문)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาอังกฤษ)',
    vi: 'Nồng độ cồn (Tiếng Anh)',
  },
  cnAlcohol: {
    ja: 'アルコール度数 (簡体字)',
    en: 'Alcohol Content (Simplified Chinese)',
    zhcn: '酒精（简体字）',
    zhtw: '酒精（簡體字）',
    ko: '알코올 도수 (간체)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาจีนตัวย่อ)',
    vi: 'Nồng độ cồn (Tiếng Trung giản thể)',
  },
  hkAlcohol: {
    ja: 'アルコール度数 (繁体字)',
    en: 'Alcohol Content (Traditional Chinese)',
    zhcn: '酒精（繁体字）',
    zhtw: '酒精（繁體字）',
    ko: '알코올 도수 (번체)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาจีนตัวเต็ม)',
    vi: 'Nồng độ cồn (Tiếng Trung phồn thể)',
  },
  krAlcohol: {
    ja: 'アルコール度数 (韓国語)',
    en: 'Alcohol Content (Korean)',
    zhcn: '酒精（韩国）',
    zhtw: '酒精（韓國）',
    ko: '알코올 도수 (한국어)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาเกาหลี)',
    vi: 'Nồng độ cồn (Tiếng Hàn)',
  },
  thAlcohol: {
    ja: 'アルコール度数 (タイ語)',
    en: 'Alcohol Content (Thai)',
    zhcn: '酒精（泰国）',
    zhtw: '酒精（泰國）',
    ko: '알코올 도수 (태국어)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาไทย)',
    vi: 'Nồng độ cồn (Tiếng Thái)',
  },
  viAlcohol: {
    ja: 'アルコール度数 (ベトナム語)',
    en: 'Alcohol Content (Vietnamese)',
    zhcn: '酒精（越南）',
    zhtw: '酒精（越南）',
    ko: '알코올 도수 (베트남어)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาเวียดนาม)',
    vi: 'Nồng độ cồn (Tiếng Việt)',
  },
  addNewBrand: {
    ja: '新規銘柄を追加',
    en: 'Add New Brand',
    zhcn: '添加一个新的品牌',
    zhtw: '添加一個新的品牌',
    ko: '신규 브랜드 추가',
    th: 'เพิ่มยี่ห้อใหม่',
    vi: 'Thêm thương hiệu mới',
  },
  enableDirectSettingOfImageUrl: {
    ja: '画像URLを直接設定する',
    en: 'Enable direct setting of image URL',
    zhcn: '要直接设置图像URL',
    zhtw: '要直接設置圖像URL',
    ko: '이미지 URL을 직접 설정',
    th: 'เปิดใช้งานการตั้งค่า URL รูปภาพโดยตรง',
    vi: 'Bật tính năng thiết lập trực tiếp URL hình ảnh',
  },
  enableDirectSettingOfLogoImageUrl: {
    ja: 'ロゴ画像URLを直接設定する',
    en: 'Enable direct setting of logo image URL',
    zhcn: '直接设置标志图像URL',
    zhtw: '直接設置標誌圖像URL',
    ko: '로고 이미지 URL을 직접 설정',
    th: 'เปิดใช้งานการตั้งค่า URL รูปภาพโลโก้โดยตรง',
    vi: 'Bật tính năng thiết lập trực tiếp URL hình ảnh logo',
  },
  user: {
    ja: 'ユーザ',
    en: 'User',
    zhcn: '用户',
    zhtw: '用戶',
    ko: '사용자',
    th: 'ผู้ใช้',
    vi: 'Người dùng',
  },
  title: {
    ja: 'タイトル',
    en: 'Title',
    zhcn: '标题',
    zhtw: '標題',
    ko: '제목',
    th: 'ชื่อเรื่อง',
    vi: 'Tiêu đề',
  },
  slipNum: {
    ja: '伝票番号',
    en: 'Slip Number',
    zhcn: '单号',
    zhtw: '單號',
    ko: '전표 번호',
    th: 'หมายเลขสลิป',
    vi: 'Số phiếu',
  },
  registrationNum: {
    ja: '登録番号',
    en: 'Registration Number',
    zhcn: '注册号码',
    zhtw: '註冊號碼',
    ko: '등록 번호',
    th: 'หมายเลขลงทะเบียน',
    vi: 'Số đăng ký',
  },
  detailNum: {
    ja: '詳細番号',
    en: 'Detail Number',
    zhcn: '详细号',
    zhtw: '詳細號',
    ko: '상세 번호',
    th: 'หมายเลขรายละเอียด',
    vi: 'Số chi tiết',
  },
  details: {
    ja: '詳細',
    en: 'Detail',
    zhcn: '更多详情',
    zhtw: '更多詳情',
    ko: '상세',
    th: 'รายละเอียด',
    vi: 'Chi tiết',
  },
  openDetails: {
    ja: '詳細情報を開く',
    en: 'Open Detail',
    zhcn: '打开了解更多信息',
    zhtw: '打開了解更多信息',
    ko: '상세 정보 열기',
    th: 'เปิดรายละเอียด',
    vi: 'Mở chi tiết',
  },
  operation: {
    ja: '業務区分',
    en: 'Operation Type',
    zhcn: '业务线',
    zhtw: '業務線',
    ko: '업무 구분',
    th: 'ประเภทการดำเนินงาน',
    vi: 'Loại hoạt động',
  },
  quantity: {
    ja: '数量',
    en: 'Quantity',
    zhcn: '数量',
    zhtw: '數量',
    ko: '수량',
    th: 'ปริมาณ',
    vi: 'Số lượng',
  },
  qualityAlertDuration: {
    ja: '在庫品質認定期間',
    en: 'Quality Control Alert Duration',
    zhcn: '库存质量认证期限',
    zhtw: '庫存質量認證期限',
    ko: '재고 품질 인증 기간',
    th: 'ระยะเวลาการแจ้งเตือนคุณภาพสินค้าคงคลัง',
    vi: 'Thời gian cảnh báo kiểm soát chất lượng',
  },
  qualityAlert: {
    ja: '品質アラート',
    en: 'Quality alert',
    zhcn: '质量警报',
    zhtw: '質量警報',
    ko: '품질 경고',
    th: 'การแจ้งเตือนคุณภาพ',
    vi: 'Cảnh báo chất lượng',
  },
  scanLatitude: {
    ja: '緯度（スキャン時）',
    en: 'Scan Latitude',
    zhcn: '纬度（在扫描期间）',
    zhtw: '緯度（在掃描期間）',
    ko: '위도 (스캔)',
    th: 'ละติจูด (ในขณะสแกน)',
    vi: 'Vĩ độ (khi quét)',
  },
  scanLongitude: {
    ja: '経度（スキャン時）',
    en: 'Scan Longitude',
    zhcn: '经度（在扫描的时间）',
    zhtw: '經度（在掃描的時間）',
    ko: '경도 (스캔)',
    th: 'ลองจิจูด (ในขณะสแกน)',
    vi: 'Kinh độ (khi quét)',
  },
  totalSalesContractsCounts: {
    ja: '販売契約数',
    en: 'Sales Contract Quantity',
    zhcn: '销售合同号',
    zhtw: '銷售合同號',
    ko: '판매 계약 수',
    th: 'จำนวนสัญญาการขาย',
    vi: 'Số lượng hợp đồng bán hàng',
  },
  totalBrewShipCounts: {
    ja: '総酒蔵出荷数',
    en: 'Total Brewery Shipping Quantity',
    zhcn: '总出货量啤酒厂',
    zhtw: '總出貨量啤酒廠',
    ko: '총 양조장 출하 수량',
    th: 'ปริมาณการส่งออกทั้งหมดจากโรงเบียร์',
    vi: 'Tổng số lượng vận chuyển từ nhà máy',
  },
  totalRestRecvCounts: {
    ja: '総レストラン入荷数',
    en: 'Total Restaurant Receiving Quantity',
    zhcn: '餐厅的总收货量',
    zhtw: '餐廳的總收貨量',
    ko: '총 레스토랑 입하 수량',
    th: 'ปริมาณการรับสินค้าทั้งหมดจากร้านอาหาร',
    vi: 'Tổng số lượng nhận hàng từ nhà hàng',
  },
  totalRestSoldCounts: {
    ja: '総レストラン販売数',
    en: 'Total Restaurant Sales Quantity',
    zhcn: '餐厅的总销售',
    zhtw: '餐廳的總銷售',
    ko: '총 레스토랑 판매량',
    th: 'ปริมาณการขายทั้งหมดของร้านอาหาร',
    vi: 'Tổng số lượng bán hàng của nhà hàng',
  },
  totalRestStockCounts: {
    ja: '総レストラン在庫数',
    en: 'Total Restaurant Inventory Quantity',
    zhcn: '餐厅的总库存',
    zhtw: '餐廳的總庫存',
    ko: '총 레스토랑 재고 수량',
    th: 'ปริมาณสินค้าคงคลังทั้งหมดของร้านอาหาร',
    vi: 'Tổng số lượng tồn kho của nhà hàng',
  },
  year: {
    ja: '年',
    en: 'Year',
    zhcn: '年',
    zhtw: '年',
    ko: '년',
    th: 'ปี',
    vi: 'Năm',
  },
  yearShort: {
    ja: '年',
    en: 'Y',
    zhcn: '年',
    zhtw: '年',
    ko: '년',
    th: 'ปี',
    vi: 'Năm',
  },
  month: {
    ja: '月',
    en: 'Month',
    zhcn: '月',
    zhtw: '月',
    ko: '월',
    th: 'เดือน',
    vi: 'Tháng',
  },
  monthShort: {
    ja: '月',
    en: 'M',
    zhcn: '月',
    zhtw: '月',
    ko: '월',
    th: 'เดือน',
    vi: 'Tháng',
  },
  chart: {
    ja: 'チャート',
    en: 'Chart',
    zhcn: '图表',
    zhtw: '圖表',
    ko: '차트',
    th: 'แผนภูมิ',
    vi: 'Biểu đồ',
  },
  table: {
    ja: 'テーブル',
    en: 'Table',
    zhcn: '桌子',
    zhtw: '桌子',
    ko: '테이블',
    th: 'ตาราง',
    vi: 'Bảng',
  },
  price: {
    ja: '価格',
    en: 'Price',
    zhcn: '价格',
    zhtw: '價格',
    ko: '가격',
    th: 'ราคา',
    vi: 'Giá',
  },
  form: {
    ja: '形態',
    en: 'Form',
    zhcn: '形式',
    zhtw: '形式',
    ko: '형태',
    th: 'รูปแบบ',
    vi: 'Hình thức',
  },
  temperature: {
    ja: '温度',
    en: 'Temperature',
    zhcn: '温度',
    zhtw: '溫度',
    ko: '온도',
    th: 'อุณหภูมิ',
    vi: 'Nhiệt độ',
  },
  stock: {
    ja: '在庫',
    en: 'Inventory',
    zhcn: '库存',
    zhtw: '庫存',
    ko: '재고',
    th: 'สินค้าคงคลัง',
    vi: 'Tồn kho',
  },
  stockCount: {
    ja: '在庫数',
    en: 'Inventory Quantity',
    zhcn: '库存数',
    zhtw: '庫存數',
    ko: '재고 수량',
    th: 'ปริมาณสินค้าคงคลัง',
    vi: 'Số lượng tồn kho',
  },
  min: {
    ja: '最小',
    en: 'Min',
    zhcn: '最低限度',
    zhtw: '最低限度',
    ko: '최소',
    th: 'ต่ำสุด',
    vi: 'Tối thiểu',
  },
  max: {
    ja: '最大',
    en: 'Max',
    zhcn: '最大限度',
    zhtw: '最大限度',
    ko: '최대',
    th: 'สูงสุด',
    vi: 'Tối đa',
  },
  sellingPrice: {
    ja: '販売価格',
    en: 'Sales Price',
    zhcn: '售价',
    zhtw: '售價',
    ko: '판매 가격',
    th: 'ราคาขาย',
    vi: 'Giá bán',
  },
  sellingForm: {
    ja: '販売形態',
    en: 'Sales Unit',
    zhcn: '销售形式',
    zhtw: '銷售形式',
    ko: '판매 형태',
    th: 'หน่วยการขาย',
    vi: 'Đơn vị bán hàng',
  },
  executor: {
    ja: '実施者',
    en: 'Conductor',
    zhcn: '从业者',
    zhtw: '從業者',
    ko: '실시하는 사람',
    th: 'ผู้ดำเนินการ',
    vi: 'Người thực hiện',
  },
  narrowDown: {
    ja: '絞り込み',
    en: 'Narrow Down',
    zhcn: '过筛',
    zhtw: '過濾',
    ko: '좁히다',
    th: 'จำกัด',
    vi: 'Hạn chế',
  },
  editProfile: {
    ja: 'プロフィール編集',
    en: 'Edit Profile',
    zhcn: '编辑个人资料',
    zhtw: '編輯個人資料',
    ko: '프로필 수정',
    th: 'แก้ไขโปรไฟล์',
    vi: 'Chỉnh sửa hồ sơ',
  },
  sakeRegistration: {
    ja: '流通履歴登録',
    en: 'Distribution Record Registration',
    zhcn: '分布履历登记',
    zhtw: '分佈履歷登記',
    ko: '유통 이력 등록',
    th: 'ลงทะเบียนประวัติการจัดจำหน่าย',
    vi: 'Đăng ký lịch sử phân phối',
  },
  serialRegistration: {
    ja: 'シリアル番号管理',
    en: 'Serial Number Management',
    zhcn: '序列号管理',
    zhtw: '序列號管理',
    ko: '일련 번호 관리',
    th: 'การจัดการหมายเลขซีเรียล',
    vi: 'Quản lý số sê-ri',
  },
  back: {
    ja: '戻る',
    en: 'Back',
    zhcn: '回去',
    zhtw: '回去',
    ko: '돌아 가기',
    th: 'กลับ',
    vi: 'Quay lại',
  },
  next: {
    ja: '次へ',
    en: 'Next',
    zhcn: '下一个',
    zhtw: '下一個',
    ko: '다음',
    th: 'ถัดไป',
    vi: 'Tiếp theo',
  },
  finish: {
    ja: '完了',
    en: 'Finish',
    zhcn: '完成',
    zhtw: '完成',
    ko: '완료',
    th: 'เสร็จสิ้น',
    vi: 'Hoàn thành',
  },
  restaurant2: {
    ja: '出荷先 (レストラン)',
    en: 'Shipping Destination (Restaurant)',
    zhcn: '运送目的地（餐馆）',
    zhtw: '運送目的地（餐館）',
    ko: '출하처 (레스토랑)',
    th: 'สถานที่ส่ง (ร้านอาหาร)',
    vi: 'Địa điểm vận chuyển (Nhà hàng)',
  },
  destination: {
    ja: '出荷先',
    en: 'Shipping Destination',
    zhcn: '运送目的地',
    zhtw: '運送目的地',
    ko: '출하처',
    th: 'สถานที่ส่ง',
    vi: 'Địa điểm vận chuyển',
  },
  receiveDate: {
    ja: '入荷日',
    en: 'Receiving Date',
    zhcn: '抵达当日',
    zhtw: '抵達當日',
    ko: '입하 일',
    th: 'วันที่รับสินค้า',
    vi: 'Ngày nhận hàng',
  },
  pleaseSelect: {
    ja: '選択してください...',
    en: 'Please select...',
    zhcn: '请选择...',
    zhtw: '請選擇...',
    ko: '선택하십시오',
    th: 'กรุณาเลือก...',
    vi: 'Vui lòng chọn...',
  },
  pleaseEnter: {
    ja: '入力してください...',
    en: 'Please enter...',
    zhcn: '请输入...',
    zhtw: '請輸入...',
    ko: '입력하십시오',
    th: 'กรุณาป้อน...',
    vi: 'Vui lòng nhập...',
  },
  setShipDestAndSlip: {
    ja: '出荷先と伝票番号を設定する',
    en: 'Set Shipping Destination and Slip Number',
    zhcn: '设置出货目的地和证件号码',
    zhtw: '設置出貨目的地和證件號碼',
    ko: '출하처 및 전표 번호를 설정',
    th: 'ตั้งค่าสถานที่ส่งและหมายเลขสลิป',
    vi: 'Thiết lập địa điểm vận chuyển và số phiếu',
  },
  selectOperation: {
    ja: '業務区分を選択',
    en: 'Select Operation Type',
    zhcn: '选择业务线',
    zhtw: '選擇業務線',
    ko: '업무 구분을 선택',
    th: 'เลือกประเภทการดำเนินงาน',
    vi: 'Chọn loại hoạt động',
  },
  scanQRCode: {
    ja: 'QRコードをスキャンする',
    en: 'Scan QR Code',
    zhcn: '扫描QR码',
    zhtw: '掃描QR碼',
    ko: 'QR 코드를 스캔',
    th: 'สแกน QR Code',
    vi: 'Quét mã QR',
  },
  startScan: {
    ja: 'スキャンを開始',
    en: 'Start Scanning',
    zhcn: '开始扫描',
    zhtw: '開始掃描',
    ko: '스캔 시작',
    th: 'เริ่มสแกน',
    vi: 'Bắt đầu quét',
  },
  qrReader: {
    ja: 'QR読み込み',
    en: 'QR Reader',
    zhcn: 'QR读取',
    zhtw: 'QR讀取',
    ko: 'QR 읽기',
    th: 'QR Reader',
    vi: 'QR Reader',
  },
  useQRCodeScanner: {
    ja: 'QRコードURLを入力',
    en: 'Input QR code URL',
    zhcn: '使用外部QR扫描',
    zhtw: '使用外部QR掃描',
    ko: 'QR 스캐너를 이용',
    th: 'ป้อน URL ของ QR code',
    vi: 'Nhập URL mã QR',
  },
  serialCodeList: {
    ja: 'シリアル番号一覧',
    en: 'Serial Number List',
    zhcn: '号列表',
    zhtw: '號列表',
    ko: '시리얼 번호 목록',
    th: 'รายการหมายเลขซีเรียล',
    vi: 'Danh sách số sê-ri',
  },
  scannedSerials: {
    ja: 'スキャン本数',
    en: 'Scanned Quantity',
    zhcn: '扫描数',
    zhtw: '掃描數',
    ko: '스캔 갯수',
    th: 'จำนวนที่สแกน',
    vi: 'Số lượng đã quét',
  },
  registeredLocationInformation: {
    ja: '登録位置情報',
    en: 'Registered Location Information',
    zhcn: '报名地点信息',
    zhtw: '報名地點信息',
    ko: '등록 위치 정보',
    th: 'ข้อมูลสถานที่ที่ลงทะเบียน',
    vi: 'Thông tin vị trí đã đăng ký',
  },
  positionInfoWhenScanning: {
    ja: 'スキャン位置情報',
    en: 'Scanned Location Information',
    zhcn: '扫描位置信息',
    zhtw: '掃描位置信息',
    ko: '스캔 위치 정보',
    th: 'ข้อมูลสถานที่ที่สแกน',
    vi: 'Thông tin vị trí đã quét',
  },
  listOfScannedQRData: {
    ja: 'スキャンデータ一覧',
    en: 'Scanned QR Data List',
    zhcn: '扫描数据列表',
    zhtw: '掃描數據列表',
    ko: '스캔 데이터 목록',
    th: 'รายการข้อมูล QR ที่สแกน',
    vi: 'Danh sách dữ liệu QR đã quét',
  },
  pleaseBringTheQRCodeCloserToTheCamera: {
    ja: '*QRをカメラに近づけてください',
    en: '*Please move QR code closer to the camera',
    zhcn: '*请将QR码贴近相机',
    zhtw: '*請將QR碼貼近相機',
    ko: '*QR 코드를 카메라에 가까이 하십시오',
    th: '*กรุณานำ QR code ใกล้กับกล้อง',
    vi: '*Vui lòng đưa mã QR gần gần camera',
  },
  reading: {
    ja: '読み取り中...',
    en: 'Reading…',
    zhcn: '读取中...',
    zhtw: '讀取中 …',
    ko: '읽는 중',
    th: 'กำลังอ่าน...',
    vi: 'Đang đọc...',
  },
  import: {
    ja: '取り込む',
    en: 'Import',
    zhcn: '进口',
    zhtw: '進口',
    ko: '캡처',
    th: 'นำเข้า',
    vi: 'Nhập khẩu',
  },
  invalidQRCode: {
    ja: '無効なQRコードです',
    en: 'Invalid QR code',
    zhcn: '无效的QR码',
    zhtw: '無效的QR碼',
    ko: '잘못된 QR 코드',
    th: 'QR code ไม่ถูกต้อง',
    vi: 'Mã QR không hợp lệ',
  },
  thisQRCodeHasAlreadyBeenScanned: {
    ja: 'すでにスキャン済みのQRコードです',
    en: 'This QR code has already been scanned',
    zhcn: '它已经扫描QR码',
    zhtw: '它已經掃描QR碼',
    ko: '이미 스캔 된 QR 코드',
    th: 'QR code นี้ถูกสแกนแล้ว',
    vi: 'Mã QR này đã được quét',
  },
  doYouWantToImportThisData: {
    ja: '以下のデータをインポートしますか？',
    en: 'Import this data?',
    zhcn: '你确定你要导入以下数据？',
    zhtw: '你確定你要導入以下數據？',
    ko: '해당 데이터를 가져 오시겠습니까?',
    th: 'คุณแน่ใจหรือว่าต้องการนำเข้าข้อมูลนี้?',
    vi: 'Bạn có chắc chắn muốn nhập dữ liệu này không?',
  },
  url: {
    ja: 'URL',
    en: 'URL',
    zhcn: '网址',
    zhtw: '網址',
    ko: 'URL',
    th: 'URL',
    vi: 'URL',
  },
  area: {
    ja: 'エリア名',
    en: 'Area Name',
    zhcn: '区域名',
    zhtw: '區域名',
    ko: '지역 이름',
    th: 'ชื่อพื้นที่',
    vi: 'Tên khu vực',
  },
  successfulScan: {
    ja: 'スキャン成功',
    en: 'Scan successful',
    zhcn: '扫描成功',
    zhtw: '掃描成功',
    ko: '스캔 성공',
    th: 'สแกนสำเร็จ',
    vi: 'Quét thành công',
  },
  pleasePasteTheQRDataMessage: {
    ja: '*QRスキャナーで読み取ったデータを、そのまま貼り付けてください',
    en: '*Please paste data read by QR scanner in this text field',
    zhcn: '*数据由QR扫描仪读取，请复制并粘贴',
    zhtw: '*數據由QR掃描儀讀取，請複製並貼上',
    ko: '*QR 스캐너로 스캔 한 데이터를 그대로 붙여 넣으십시오',
    th: '*กรุณาวางข้อมูลที่อ่านได้จาก QR scanner ลงในช่องข้อความนี้',
    vi: '*Vui lòng dán dữ liệu đã được đọc bởi QR scanner vào trường văn bản này',
  },
  addSerial: {
    ja: 'シリアル番号を追加',
    en: 'Add Serial Number',
    zhcn: '添加序列号',
    zhtw: '添加序列號',
    ko: '일련 번호를 추가',
    th: 'เพิ่มหมายเลขซีเรียล',
    vi: 'Thêm số sê-ri',
  },
  unregisteredSerialNumber: {
    ja: '*未登録シリアル番号 (登録不可)',
    en: '*Unregistered serial number',
    zhcn: '*未注册的序列号（注册是不可能的）',
    zhtw: '*未註冊的序列號（註冊是不可能的）',
    ko: '*미등록 일련 번호 (등록 불가)',
    th: '*หมายเลขซีเรียลที่ไม่ได้ลงทะเบียน (ไม่สามารถลงทะเบียนได้)',
    vi: '*Số sê-ri chưa đăng ký (không thể đăng ký)',
  },
  brandBreweryDoesNotBelongToOwnBrewery: {
    ja: '*酒蔵が異なる (登録不可)',
    en: "*Brand's brewery does not belong to own brewery",
    zhcn: '*啤酒厂是不同的（注册是不可能的）',
    zhtw: '*啤酒廠是不同的（註冊是不可能的）',
    ko: '*양조장이 다른 (등록 불가)',
    th: '*โรงเบียร์ของแบรนด์ไม่ตรงกับโรงเบียร์ของตน (ไม่สามารถลงทะเบียนได้)',
    vi: '*Nhà máy bia của thương hiệu không thuộc về nhà máy bia của chính họ (không thể đăng ký)',
  },
  differentFromBrewShipping: {
    ja: '*酒蔵出荷先と異なる',
    en: '*Different from the brewery shipping destination',
    zhcn: '*从酿酒厂发货目的地不同',
    zhtw: '*從釀酒廠發貨目的地不同',
    ko: '*양조장 출하 위치와 다름',
    th: '*แตกต่างจากจุดหมายปลายทางการจัดส่งของโรงเบียร์',
    vi: '*Khác với điểm đến giao hàng của nhà máy bia',
  },
  differentFromRestRecv: {
    ja: '*レストラン入荷先と異なる',
    en: '*Different from the designated receiving restaurant',
    zhcn: '与收货餐厅不同',
    zhtw: '與收貨餐廳不同',
    ko: '*입하 레스토랑과 다름',
    th: '*แตกต่างจากร้านอาหารที่รับสินค้า',
    vi: '*Khác với nhà hàng được chỉ định để nhận hàng',
  },
  incompleteRecv: {
    ja: '*レストラン入荷手続未完了 (登録不可)',
    en: '*Incomplete restaurant receiving',
    zhcn: '*餐厅库存过程没有完成（注册是不可能的）',
    zhtw: '*餐廳庫存過程沒有完成（註冊是不可能的）',
    ko: '*레스토랑 입하 절차 미완료 (등록 불가)',
    th: '*กระบวนการรับสินค้าของร้านอาหารยังไม่เสร็จสิ้น (ไม่สามารถลงทะเบียนได้)',
    vi: '*Quá trình nhận hàng tại nhà hàng chưa hoàn thành (không thể đăng ký)',
  },
  checkDataAndRegister: {
    ja: '最終確認・登録する',
    en: 'Finalize Data and Register',
    zhcn: '最终确认和登记',
    zhtw: '最終確認和登記',
    ko: '최종 확인 · 등록하기',
    th: 'ยืนยันและลงทะเบียน',
    vi: 'Hoàn tất dữ liệu và đăng ký',
  },
  search: {
    ja: '検索',
    en: 'Search',
    zhcn: '搜寻',
    zhtw: '搜尋',
    ko: '검색',
    th: 'ค้นหา',
    vi: 'Tìm kiếm',
  },
  receiving: {
    ja: '入荷',
    en: 'Receiving',
    zhcn: '货到',
    zhtw: '貨到',
    ko: '입하',
    th: 'รับของ',
    vi: 'Nhận hàng',
  },
  selling: {
    ja: '販売',
    en: 'Selling',
    zhcn: '销售',
    zhtw: '銷售',
    ko: '판매',
    th: 'ขาย',
    vi: 'Bán hàng',
  },
  comment: {
    ja: 'コメント',
    en: 'Comment',
    zhcn: '评论',
    zhtw: '評論',
    ko: '댓글',
    th: 'ความคิดเห็น',
    vi: 'Bình luận',
  },
  commentList: {
    ja: 'コメント一覧',
    en: 'Comment List',
    zhcn: '评论列表',
    zhtw: '評論列表',
    ko: '댓글 목록',
    th: 'รายการความคิดเห็น',
    vi: 'Danh sách bình luận',
  },
  createDate: {
    ja: '作成日時',
    en: 'Create Date/Time',
    zhcn: '创建日期和时间',
    zhtw: '創建日期和時間',
    ko: '작성 시간',
    th: 'วันที่/เวลาที่สร้าง',
    vi: 'Ngày/Thời gian tạo',
  },
  updateDate: {
    ja: '更新日時',
    en: 'Update Date/Time',
    zhcn: '修改日期',
    zhtw: '修改日期',
    ko: '업데이트 날짜',
    th: 'วันที่/เวลาที่อัปเดต',
    vi: 'Ngày/Thời gian cập nhật',
  },
  reload: {
    ja: 'リロード',
    en: 'Reload',
    zhcn: '刷新',
    zhtw: '刷新',
    ko: '새로 고침',
    th: 'โหลดใหม่',
    vi: 'Tải lại',
  },
  filter: {
    ja: 'フィルター',
    en: 'Filter',
    zhcn: '筛选',
    zhtw: '篩選',
    ko: '필터',
    th: 'ตัวกรอง',
    vi: 'Bộ lọc',
  },
  filterClose: {
    ja: 'フィルターを閉じる',
    en: 'Close Filter',
    zhcn: '关闭过滤器',
    zhtw: '關閉過濾器',
    ko: '필터 닫기',
    th: 'ปิดตัวกรอง',
    vi: 'Đóng bộ lọc',
  },
  filterOpen: {
    ja: 'フィルターを開く',
    en: 'Open Filter',
    zhcn: '打开过滤器',
    zhtw: '打開過濾器',
    ko: '필터 열기',
    th: 'เปิดตัวกรอง',
    vi: 'Mở bộ lọc',
  },
  filterReset: {
    ja: 'フィルターリセット',
    en: 'Reset Filter',
    zhcn: '过滤器复位',
    zhtw: '過濾器復位',
    ko: '필터 재설정',
    th: 'รีเซ็ตตัวกรอง',
    vi: 'Đặt lại bộ lọc',
  },
  unspecified: {
    ja: '指定なし',
    en: 'Unspecified',
    zhcn: '不明',
    zhtw: '不明',
    ko: '지정 없음',
    th: 'ไม่ได้ระบุ',
    vi: 'Chưa xác định',
  },
  empty: {
    ja: '-',
    en: '-',
    zhcn: '-',
    zhtw: '-',
    ko: '-',
    th: '-',
    vi: '-',
  },
  inputPeriod: {
    ja: '期間を指定',
    en: 'Designate Duration',
    zhcn: '指定时间段',
    zhtw: '指定時間段',
    ko: '기간 지정',
    th: 'ระบุระยะเวลา',
    vi: 'Chỉ định khoảng thời gian',
  },
  today: {
    ja: '今日',
    en: 'Today',
    zhcn: '今天',
    zhtw: '今天',
    ko: '오늘',
    th: 'วันนี้',
    vi: 'Hôm nay',
  },
  yesterday: {
    ja: '昨日',
    en: 'Yesterday',
    zhcn: '昨天',
    zhtw: '昨天',
    ko: '어제',
    th: 'เมื่อวาน',
    vi: 'Hôm qua',
  },
  lastSevenDays: {
    ja: '過去7日間',
    en: 'Last 7 Days',
    zhcn: '过去7天',
    zhtw: '過去7天',
    ko: '지난 7 일',
    th: '7 วันที่ผ่านมา',
    vi: '7 ngày qua',
  },
  ok: {
    ja: '決定',
    en: 'OK',
    zhcn: '决定',
    zhtw: '決定',
    ko: '결정',
    th: 'ตกลง',
    vi: 'Đồng ý',
  },
  daySunday: {
    ja: '日',
    en: 'Sun',
    zhcn: '日',
    zhtw: '日',
    ko: '일',
    th: 'อา',
    vi: 'CN',
  },
  dayMonday: {
    ja: '月',
    en: 'Mon',
    zhcn: '一',
    zhtw: '一',
    ko: '월',
    th: 'จ',
    vi: 'T2',
  },
  dayTuesday: {
    ja: '火',
    en: 'Tue',
    zhcn: '二',
    zhtw: '二',
    ko: '화',
    th: 'อ',
    vi: 'T3',
  },
  dayWednesday: {
    ja: '水',
    en: 'Wed',
    zhcn: '三',
    zhtw: '三',
    ko: '수',
    th: 'พ',
    vi: 'T4',
  },
  dayThursday: {
    ja: '木',
    en: 'Thur',
    zhcn: '四',
    zhtw: '四',
    ko: '목',
    th: 'พฤ',
    vi: 'T5',
  },
  dayFriday: {
    ja: '金',
    en: 'Fri',
    zhcn: '五',
    zhtw: '五',
    ko: '금',
    th: 'ศ',
    vi: 'T6',
  },
  daySaturday: {
    ja: '土',
    en: 'Sat',
    zhcn: '六',
    zhtw: '六',
    ko: '토',
    th: 'ส',
    vi: 'T7',
  },
  by: {
    ja: '別',
    en: 'by',
    zhcn: '不同的',
    zhtw: '不同的',
    ko: '각각',
    th: 'โดย',
    vi: 'theo',
  },
  warning: {
    ja: '警告',
    en: 'Alert',
    zhcn: '警告',
    zhtw: '警告',
    ko: '경고',
    th: 'การแจ้งเตือน',
    vi: 'Cảnh báo',
  },
  withWarning: {
    ja: '警告あり',
    en: 'Alerted',
    zhcn: '已警告',
    zhtw: '已警告',
    ko: '경고 있음',
    th: 'มีการแจ้งเตือน',
    vi: 'Đã được cảnh báo',
  },
  noWarning: {
    ja: '警告なし',
    en: 'No Alert',
    zhcn: '没有警告',
    zhtw: '沒有警告',
    ko: '경고 없음',
    th: 'ไม่มีการแจ้งเตือน',
    vi: 'Không cảnh báo',
  },
  unauthorizedDistributor: {
    ja: '不正流通',
    en: 'Unauthorized Distribution',
    zhcn: '非法传播',
    zhtw: '非法傳播',
    ko: '부정 유통',
    th: 'การกระจายสินค้าโดยไม่ได้รับอนุญาต',
    vi: 'Phân phối không được phép',
  },
  investigationRequired: {
    ja: '要調査',
    en: 'Investigation Required',
    zhcn: '必須调查',
    zhtw: '必須調查',
    ko: '요구 조사',
    th: 'ต้องมีการตรวจสอบ',
    vi: 'Cần điều tra',
  },
  deleteHistory: {
    ja: '履歴を削除',
    en: 'Delete Record',
    zhcn: '删除历史记录',
    zhtw: '刪除歷史記錄',
    ko: '기록 삭제',
    th: 'ลบประวัติ',
    vi: 'Xóa lịch sử',
  },
  history: {
    ja: '履歴',
    en: 'Record',
    zhcn: '历史',
    zhtw: '歷史',
    ko: '기록',
    th: 'ประวัติ',
    vi: 'Lịch sử',
  },
  monthlyTransiton: {
    ja: '月次推移',
    en: 'Monthly Transition',
    zhcn: '月度趋势',
    zhtw: '月度趨勢',
    ko: '월별 추이',
    th: 'การเปลี่ยนแปลงรายเดือน',
    vi: 'Chuyển đổi hàng tháng',
  },
  csvDownload: {
    ja: 'CSVダウンロード',
    en: 'CSV Download',
    zhcn: 'CSV下载',
    zhtw: 'CSV下載',
    ko: 'CSV 다운로드',
    th: 'ดาวน์โหลด CSV',
    vi: 'Tải xuống CSV',
  },
  searchByName: {
    ja: '名前で検索',
    en: 'Search by name',
    zhcn: '搜索名字',
    zhtw: '搜索名字',
    ko: '이름으로 검색',
    th: 'ค้นหาด้วยชื่อ',
    vi: 'Tìm kiếm theo tên',
  },
  dispMonthlyTransiton: {
    ja: '月次推移を表示する',
    en: 'Display Monthly Transition',
    zhcn: '显示的月变化',
    zhtw: '顯示的月變化',
    ko: '월별 추이 확인',
    th: 'แสดงการเปลี่ยนแปลงรายเดือน',
    vi: 'Hiển thị sự thay đổi hàng tháng',
  },
  viewEditHistory: {
    ja: '編集履歴を表示する',
    en: 'View Editing History',
    zhcn: '查看编​​辑历史',
    zhtw: '查看編輯歷史',
    ko: '수정 이력보기',
    th: 'ดูประวัติการแก้ไข',
    vi: 'Xem lịch sử chỉnh sửa',
  },
  deleteConfirmation: {
    ja: 'このデータを削除してもよろしいですか？',
    en: 'Confirm to delete this data?',
    zhcn: '你确定要删除这些数据？',
    zhtw: '你確定要刪除這些數據？',
    ko: '이 데이터를 삭제 하시겠습니까?',
    th: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
    vi: 'Xác nhận để xóa dữ liệu này?',
  },
  baseRegistration: {
    ja: '登録拠点',
    en: 'Location Registration',
    zhcn: '注册办事处',
    zhtw: '註冊辦事處',
    ko: '등록 지점',
    th: 'การลงทะเบียนสถานที่',
    vi: 'Đăng ký địa điểm',
  },
  coordinates: {
    ja: '緯度経度',
    en: 'Longitude/Latitude',
    zhcn: '经度和纬度',
    zhtw: '經度和緯度',
    ko: '위도 경도',
    th: 'ลองจิจูด/ละติจูด',
    vi: 'Kinh độ/Vĩ độ',
  },
  targetData: {
    ja: '対象データ',
    en: 'Target data',
    zhcn: '数据对象',
    zhtw: '數據對象',
    ko: '대상 데이터',
    th: 'ข้อมูลเป้าหมาย',
    vi: 'Dữ liệu mục tiêu',
  },
  editComment: {
    ja: '編集内容',
    en: 'Edited Content',
    zhcn: '编辑',
    zhtw: '編輯',
    ko: '수정',
    th: 'เนื้อหาที่แก้ไข',
    vi: 'Nội dung đã chỉnh sửa',
  },
  editDate: {
    ja: '編集日時',
    en: 'Edited Date/Time',
    zhcn: '编辑日期和时间',
    zhtw: '編輯日期和時間',
    ko: '수정 날짜',
    th: 'วันที่/เวลาที่แก้ไข',
    vi: 'Ngày/Thời gian đã chỉnh sửa',
  },
  scanLocationNotFound: {
    ja: 'スキャン位置情報が登録されていません',
    en: 'Scanned location information is not registered',
    zhcn: '扫描位置信息没有被注册',
    zhtw: '掃描位置信息沒有被註冊',
    ko: '스캔 위치 정보가 등록되어 있지 않습니다',
    th: 'ไม่ได้ลงทะเบียนข้อมูลตำแหน่งการสแกน',
    vi: 'Thông tin vị trí quét chưa được đăng ký',
  },
  scanLocationBaseError: {
    ja: 'スキャン位置と登録拠点間の距離を算出できませんでした',
    en:
      'The distance between the scanned location and the registered location can not be calculated',
    zhcn: '我无法计算登记中心和扫描位置之间的距离',
    zhtw: '我無法計算登記中心和掃描位置之間的距離',
    ko: '스캔 위치와 등록 지점 간의 거리를 계산할 수 없습니다',
    th: 'ไม่สามารถคำนวณระยะห่างระหว่างตำแหน่งการสแกนและตำแหน่งที่ลงทะเบียนได้',
    vi: 'Không thể tính khoảng cách giữa vị trí đã quét và vị trí đã đăng ký',
  },
  registeredBaseFromPrefix: {
    ja: '登録拠点から、',
    en: 'Scanned from the registered location',
    zhcn: '从登记处，',
    zhtw: '從登記處，',
    ko: '등록 지점에서 스캔 됨',
    th: 'สแกนจากสถานที่ที่ลงทะเบียน',
    vi: 'Đã quét từ địa điểm đã đăng ký',
  },
  scannedDistanceSuffix: {
    ja: 'm地点でスキャンされました',
    en: 'Scanned from Point m',
    zhcn: '它在m点进行扫描',
    zhtw: '它在m點進行掃描',
    ko: 'm 지점에서스캔 됨',
    th: 'สแกนจากจุด m',
    vi: 'Đã quét từ điểm m',
  },
  registeredBaseWithinRangeFromPrefix: {
    ja: '指定範囲内（登録拠点から、',
    en: 'Within the specified range (From the registered location',
    zhcn: '指定的范围内（注册地点起算）',
    zhtw: '指定的範圍內（註冊地點起算）',
    ko: '지정 범위 내 (등록 지점에서',
    th: 'อยู่ในช่วงที่กำหนด (จากสถานที่ที่ลงทะเบียน',
    vi: 'Trong phạm vi chỉ định (Từ địa điểm đã đăng ký',
  },
  registeredBaseOutsideRangeFromPrefix: {
    ja: '指定範囲外（登録拠点より、',
    en: 'Scanned outside of the specified range (From the registered location',
    zhcn: '指定的范围外（注册地点起算）',
    zhtw: '指定範圍外（註冊地點起算）',
    ko: '지정 범위 외 (등록 지점보다',
    th: 'นอกช่วงที่กำหนด (จากสถานที่ที่ลงทะเบียน',
    vi: 'Quét ngoài phạm vi đã chỉ định (Từ vị trí đã đăng ký',
  },
  registeredBase: {
    ja: '指定範囲内',
    en: 'Within the specified range',
    zhcn: '在规定范围内',
    zhtw: '在規定範圍內',
    ko: '지정 범위',
    th: 'อยู่ในช่วงที่กำหนด',
    vi: 'Trong phạm vi đã chỉ định',
  },
  scannedApproxDistanceWithinSuffix: {
    ja: 'm以内）です',
    en: 'meters under',
    zhcn: 'm米以內',
    zhtw: 'm公尺以內',
    ko: 'm 이내 입니다',
    th: 'ไม่เกินเมตร',
    vi: 'dưới m',
  },
  scannedApproxDistanceMoreSuffix: {
    ja: 'm以上離れた位置）です',
    en: 'meters or more',
    zhcn: 'm米以外',
    zhtw: 'm公尺以外',
    ko: 'm 이상 입니다',
    th: 'หรือมากกว่าเมตร',
    vi: 'mét trở lên',
  },
  baseLocationInfoNotRegistered: {
    ja: '拠点の位置情報が登録されていません',
    en: 'Location information is not registered',
    zhcn: '位置信息没有被注册',
    zhtw: '位置信息沒有被註冊',
    ko: '지점의 위치 정보가 등록되어 있지 않습니다',
    th: 'ไม่ได้ลงทะเบียนข้อมูลตำแหน่ง',
    vi: 'Thông tin vị trí chưa được đăng ký',
  },
  deleteConfirmationSuffix: {
    ja: 'を削除してもよろしいですか？',
    en: 'will be deleted. Confirm?',
    zhcn: '确定要删除？',
    zhtw: '是否要刪除？',
    ko: '을 삭제 하시겠습니까?',
    th: 'จะถูกลบ ยืนยัน?',
    vi: 'sẽ bị xóa. Xác nhận?',
  },
  errorServerLoad: {
    ja: '一度に20件以上は登録できません、ファイルを分けて登録してください',
    en:
      'No more than 20 items can be registered in a single attempt, please register files separately',
    zhcn: '系统一次无法上载20个以上档案，请分开上载',
    zhtw: '系統一次無法上載20個以上檔案，請分開上傳',
    ko:
      '[제한] 서버에 부하가 걸리기 때문에 한 번에 20 건 이상 등록 할 수 없습니다 파일을 나누어 등록하십시오',
    th: 'ไม่สามารถลงทะเบียนมากกว่า 20 รายการในครั้งเดียวได้ โปรดลงทะเบียนไฟล์แยกต่างหาก',
    vi: 'Không thể đăng ký quá 20 mục trong một lần, vui lòng đăng ký các tệp riêng biệt',
  },
  errorRowNotEnoughItems: {
    ja: '行目: 項目数が足りません',
    en: 'Row: Not enough items.',
    zhcn: '行：项目的数量是不够的',
    zhtw: '行：項目的數量是不夠的',
    ko: '항목 : 항목 수가 충분하지 않습니다',
    th: 'แถว: รายการไม่เพียงพอ',
    vi: 'Dòng: Không đủ mục.',
  },
  errorRowIncompleteRequiredFieldsBrand: {
    ja: '行目: 必須項目（公開IDまたは、名前、酒蔵拠点の公開ID）が未入力です',
    en: 'Row: Required items (Public ID, Name, Brewery Public ID) are missing',
    zhcn: '行：必填项（公共ID或名称，啤酒厂网站的公共ID）未完成',
    zhtw: '行：必填項（公共ID或名稱，啤酒廠網站的公共ID）未完成',
    ko: '항목 : 필수 항목 (공개 ID 또는 이름, 양조장 지점의 공개 ID)이 비어 있습니다',
    th: 'แถว: รายการที่จำเป็น (Public ID, Name, Brewery Public ID) หายไป',
    vi: 'Dòng: Các mục bắt buộc (ID công khai, Tên, ID công khai của nhà máy bia) bị thiếu',
  },
  errorRowIncompleteRequiredFieldsLocation1: {
    ja: '行目: 必須項目（国コードまたは、住所情報）が未入力です',
    en: 'Row: Required items (Country Code, Address) are missing',
    zhcn: '行：必填项（国家代码或地址信息）未完成',
    zhtw: '行：必填項（國家代碼或地址信息）未完成',
    ko: '항목 : 필수 항목 (국가 코드 또는 주소 정보)이 비어 있습니다',
    th: 'แถว: รายการที่จำเป็น (รหัสประเทศ ที่อยู่) หายไป',
    vi: 'Dòng: Các mục bắt buộc (Mã quốc gia, Địa chỉ) bị thiếu',
  },
  errorRowIncompleteRequiredFieldsLocation2: {
    ja: '行目: 必須項目（公開IDまたは、名前、カテゴリ）が未入力です',
    en: 'Row: Required items (Public ID, Name, Category) are missing',
    zhcn: '行：必填项（公共ID或名称，类别）未完成',
    zhtw: '行：必填字段（公共ID或名稱，類別）未完成',
    ko: '항목 : 필수 항목 (공개 ID 또는 이름, 카테고리)이 비어 있습니다',
    th: 'แถว: รายการที่จำเป็น (Public ID, Name, Category) หายไป',
    vi: 'Dòng: Các mục bắt buộc (ID công khai, Tên, Danh mục) bị thiếu',
  },
  errorRowIncompleteRequiredFieldsUser: {
    ja: '行目: 必須項目（名前または、メールアドレス、パスワード）が未入力です',
    en: 'Row: Required fields (Name, email Address, Password) are missing',
    zhcn: '行：必填字段（姓名或电子邮件地址，密码）未完成',
    zhtw: '行：必填字段（姓名或電子郵件地址，密碼）未完成',
    ko: '항목 : 필수 항목 (이름 또는 이메일 주소, 비밀번호)를 입력하지 않은입니다',
    th: 'แถว: ข้อมูลที่จำเป็น (ชื่อ ที่อยู่อีเมล รหัสผ่าน) หายไป',
    vi: 'Dòng: Trường bắt buộc (Tên, Địa chỉ email, Mật khẩu) bị thiếu',
  },
  errorRowMailAddressFormatInvalid: {
    ja: '行目: メールアドレスのフォーマットが無効です',
    en: 'Row: Invalid email address format',
    zhcn: '行：E-mail地址格式无效',
    zhtw: '行：E-mail地址格式無效',
    ko: '항목 : 이메일 형식이 잘못되었습니다',
    th: 'แถว: รูปแบบที่อยู่อีเมลไม่ถูกต้อง',
    vi: 'Dòng: Định dạng địa chỉ email không hợp lệ',
  },
  errorRowCoordinatesInvalid: {
    ja: '行目: 住所から緯度経度の情報を取得できませんでした',
    en: 'Row: Can not acquire the address longitude/latitude',
    zhcn: '行：无法从该地址的纬度和经度的信息',
    zhtw: '行：無法從該地址的緯度和經度的信息',
    ko: '항목 : 주소에서 위도 경도 정보를 얻을 수 없습니다',
    th: 'แถว: ไม่สามารถรับพิกัดลองจิจูด/ละติจูดจากที่อยู่ได้',
    vi: 'Dòng: Không thể lấy được kinh độ/vĩ độ từ địa chỉ',
  },
  errorRowAlreadyRegistered: {
    ja: '行目: すでに登録されている',
    en: '[Row] Already registered',
    zhcn: '行：它已经被注册',
    zhtw: '行：它已經被註冊',
    ko: '항목 : 이미 등록되어있습니다',
    th: 'แถว: ได้ลงทะเบียนแล้ว',
    vi: '[Dòng] Đã được đăng ký',
  },
  desu: {
    ja: 'です',
    en: '.',
    zhcn: '是',
    zhtw: '是',
    ko: '입니다',
    th: 'คือ',
    vi: 'là',
  },
  rowBrand: {
    ja: '行目: 銘柄',
    en: 'Row: Brand',
    zhcn: '行：品牌',
    zhtw: '行：品牌',
    ko: '항목 : 브랜드',
    th: 'แถว: แบรนด์',
    vi: 'Dòng: Nhãn hiệu',
  },
  rowUser: {
    ja: '行目: ユーザ',
    en: 'Row: User',
    zhcn: '行：用户',
    zhtw: '行：用戶',
    ko: '항목 : 사용자',
    th: 'แถว: ผู้ใช้',
    vi: 'Dòng: Người dùng',
  },
  addedSuffix: {
    ja: 'を登録しました',
    en: 'has been registered',
    zhcn: '被注册',
    zhtw: '已被註冊',
    ko: '등록했습니다',
    th: 'ได้รับการลงทะเบียน',
    vi: 'đã được đăng ký',
  },
  resetImage: {
    ja: '画像リセット',
    en: 'Reset Image',
    zhcn: '图像用复位',
    zhtw: '圖像用復位',
    ko: '이미지 재설정',
    th: 'รีเซ็ตภาพ',
    vi: 'Đặt lại hình ảnh',
  },
  csvFileFormatPrefix: {
    ja: 'CSVファイルのォーマットは',
    en: 'CSV file format is',
    zhcn: 'CSV文件格式',
    zhtw: 'CSV文件格式',
    ko: 'CSV 파일의 형식은',
    th: 'รูปแบบไฟล์ CSV คือ',
    vi: 'Định dạng tệp CSV là',
  },
  downloadSuffix: {
    ja: 'からダウンロードしてください',
    en: 'can be downloaded',
    zhcn: '请下载',
    zhtw: '請下載',
    ko: '에서 다운로드하십시오',
    th: 'สามารถดาวน์โหลดได้จาก',
    vi: 'có thể tải xuống từ',
  },
  csvFileContents: {
    ja: 'CSVファイルの内容',
    en: 'CSV File Contents',
    zhcn: 'CSV文件的内容',
    zhtw: 'CSV文件的內容',
    ko: 'CSV 파일의 내용',
    th: 'เนื้อหาไฟล์ CSV',
    vi: 'Nội dung tệp CSV',
  },
  csvFileBulkAdd: {
    ja: 'CSV一括登録',
    en: 'CSV File Bulk Upload',
    zhcn: 'CSV暂搁注册',
    zhtw: 'CSV暫擱註冊',
    ko: 'CSV 일괄 등록',
    th: 'อัปโหลด CSV จำนวนมาก',
    vi: 'Tải lên CSV hàng loạt',
  },
  dataUpload: {
    ja: '個別登録',
    en: 'Individual Data Registration',
    zhcn: '个人注册',
    zhtw: '個人註冊',
    ko: '개별 등록',
    th: 'การลงทะเบียนข้อมูลส่วนบุคคล',
    vi: 'Đăng ký dữ liệu cá nhân',
  },
  langJapanese: {
    ja: '日本語',
    en: 'Japanese',
    zhcn: '日语',
    zhtw: '日語',
    ko: '일본어',
    th: 'ญี่ปุ่น',
    vi: 'Tiếng Nhật',
  },
  langLocal: {
    ja: '現地語',
    en: 'Local Language',
    zhcn: '当地语言',
    zhtw: '當地語言',
    ko: '현지어',
    th: 'ภาษาท้องถิ่น',
    vi: 'Ngôn ngữ địa phương',
  },
  langEnglish: {
    ja: '英語',
    en: 'English',
    zhcn: '英语',
    zhtw: '英語',
    ko: '영어',
    th: 'อังกฤษ',
    vi: 'Tiếng Anh',
  },
  langChinese: {
    ja: '簡体字',
    en: 'Simplified Chinese',
    zhcn: '简体中文',
    zhtw: '簡體中文',
    ko: '간체',
    th: 'ภาษาจีนตัวย่อ',
    vi: 'Tiếng Trung giản thể',
  },
  langCantonese: {
    ja: '繁体字',
    en: 'Traditional Chinese',
    zhcn: '繁体中文',
    zhtw: '繁體中文',
    ko: '번체',
    th: 'ภาษาจีนตัวเต็ม',
    vi: 'Tiếng Trung phồn thể',
  },
  langKorean: {
    ja: '韓国語',
    en: 'Korean',
    zhcn: '韩语',
    zhtw: '韓語',
    ko: '한국어',
    th: 'ภาษาเกาหลี',
    vi: 'Tiếng Hàn',
  },
  langThai: {
    ja: 'タイ語',
    en: 'Thai',
    zhcn: '泰语',
    zhtw: '泰語',
    ko: '태국어',
    th: 'ภาษาไทย',
    vi: 'Tiếng Thái',
  },
  langVietnamese: {
    ja: 'ベトナム語',
    en: 'Vietnamese',
    zhcn: '越南语',
    zhtw: '越南語',
    ko: '베트남어',
    th: 'ภาษาเวียดนาม',
    vi: 'Tiếng Việt',
  },
  pageNotFound: {
    ja: '指定したページは存在しません',
    en: 'This page does not exist',
    zhcn: '指定的页面不存在',
    zhtw: '指定的頁面不存在',
    ko: '해당 페이지가 존재하지 않습니다',
    th: 'ไม่พบหน้าที่ระบุ',
    vi: 'Trang này không tồn tại',
  },
  registrationError: {
    ja: '登録処理中にエラーが発生しました',
    en: 'An issue has occured during registration',
    zhcn: '在注册过程中发生了错误',
    zhtw: '在註冊過程中發生了錯誤',
    ko: '등록 처리 중 오류가 발생했습니다',
    th: 'เกิดข้อผิดพลาดระหว่างการลงทะเบียน',
    vi: 'Đã xảy ra sự cố trong quá trình đăng ký',
  },
  registrationSuccessful: {
    ja: '登録完了しました',
    en: 'Registration successful',
    zhcn: '注册完成',
    zhtw: '註冊完成',
    ko: '등록 완료',
    th: 'ลงทะเบียนสำเร็จ',
    vi: 'Đăng ký thành công',
  },
  breweryUserScanIntervalSec: {
    ja: '酒蔵ユーザ： スキャン間隔時間（秒）',
    en: 'Brewery User: Scan Interval Time (second)',
    zhcn: '酒廠用户：扫描间隔时间（以秒计）',
    zhtw: '酒廠用戶：掃描間隔時間（以秒計）',
    ko: '양조장 사용자 : 스캔 간격 시간 (초)',
    th: 'ผู้ใช้โรงกลั่น: เวลาสแกนระหว่าง (วินาที)',
    vi: 'Người dùng nhà máy bia: Thời gian quét (giây)',
  },
  restaurantUserScanIntervalSec: {
    ja: 'レストランユーザ： スキャン間隔時間（秒）',
    en: 'Restaurant User: Scan Interval Time (second)',
    zhcn: '餐厅用户：扫描间隔时间（以秒计）',
    zhtw: '餐廳用戶：掃描間隔時間（以秒計）',
    ko: '레스토랑 사용자 : 스캔 간격 시간 (초)',
    th: 'ผู้ใช้ร้านอาหาร: เวลาสแกนระหว่าง (วินาที)',
    vi: 'Người dùng nhà hàng: Thời gian quét (giây)',
  },
  distributionUserScanIntervalSec: {
    ja: 'ディストリビュータユーザ： スキャン間隔時間（秒）',
    en: 'Distributor user: Scan interval time (seconds)',
    zhcn: '分销商用户：扫描间隔时间（以秒计）',
    zhtw: '分銷商用戶：掃描間隔時間（以秒計）',
    ko: '유통 업자 : 스캔 간격 시간 (초)',
    th: 'ผู้ใช้ตัวแทนจำหน่าย: เวลาสแกนระหว่าง (วินาที)',
    vi: 'Người dùng nhà phân phối: Thời gian quét (giây)',
  },
  fileReadFailed: {
    ja: 'ファイル読み取りに失敗しました',
    en: 'Failed to read file',
    zhcn: '无法读取文件',
    zhtw: '無法讀取文件',
    ko: '파일 읽기에 실패했습니다',
    th: 'ไม่สามารถอ่านไฟล์ได้',
    vi: 'Không đọc được tệp',
  },
  here: {
    ja: 'こちら',
    en: 'Here',
    zhcn: '点击这里',
    zhtw: '點擊這裡',
    ko: '여기',
    th: 'ที่นี่',
    vi: 'Ở đây',
  },
  csvFormatError: {
    ja: 'CSVファイルのフォーマットが不正です',
    en: 'Invalid CSV file format',
    zhcn: 'CSV文件的格式无效',
    zhtw: 'CSV文件的格式無效',
    ko: 'CSV 파일의 형식이 잘못되었습니다',
    th: 'รูปแบบไฟล์ CSV ไม่ถูกต้อง',
    vi: 'Định dạng tệp CSV không hợp lệ',
  },
  dataAcquisitionFailed: {
    ja: 'データ取得に失敗しました再読み込みをしてください',
    en: 'Data coordination failed. Please reload.',
    zhcn: '数据采集​​失败请参阅再读取',
    zhtw: '數據採集失敗請參閱再讀取',
    ko: '데이터를 가져 오는 데 실패했습니다 다시로드하십시오',
    th: 'การประสานข้อมูลล้มเหลว โปรดโหลดซ้ำ',
    vi: 'Thu thập dữ liệu thất bại. Vui lòng tải lại.',
  },
  getMapInfoFail: {
    ja: 'マップ情報の情報取得に失敗しました',
    en: 'Failed to acquire map information.',
    zhcn: '未能获取的地图信息',
    zhtw: '未能獲取的地圖信息',
    ko: '지도 정보의 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลแผนที่ได้',
    vi: 'Không lấy được thông tin bản đồ.',
  },
  getBrandInfoFailed: {
    ja: 'ブランド別の情報取得に失敗しました',
    en: 'Failed to get information by brand',
    zhcn: '未能通过品牌获取信息',
    zhtw: '未能通過品牌獲取信息',
    ko: '브랜드 별 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามแบรนด์ได้',
    vi: 'Không lấy được thông tin theo thương hiệu',
  },
  getCountryInfoFailed: {
    ja: '国別の情報取得に失敗しました',
    en: 'Failed to get information by country',
    zhcn: '未能按国家获取信息',
    zhtw: '能按國家獲取信息',
    ko: '국가 별 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามประเทศได้',
    vi: 'Không lấy được thông tin theo quốc gia',
  },
  getCityInfoFailed: {
    ja: '都市別の情報取得に失敗しました',
    en: 'Failed to get information by city',
    zhcn: '未能通过城市获取信息',
    zhtw: '未能通過城市獲取信息',
    ko: '도시 별 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามเมืองได้',
    vi: 'Không lấy được thông tin theo thành phố',
  },
  getAgeInfoFailed: {
    ja: '年齢別の情報取得に失敗しました',
    en: 'Failed to get information by age',
    zhcn: '未能通过年龄获取信息',
    zhtw: '未能通過年齡獲取信息',
    ko: '연령별 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามอายุได้',
    vi: 'Không lấy được thông tin theo độ tuổi',
  },
  getGenderInfoFailed: {
    ja: '性別別の情報取得に失敗しました',
    en: 'Failed to get information by gender',
    zhcn: '未能按性别获取信息',
    zhtw: '未能按性別獲取信息',
    ko: '성별 다른 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามเพศได้',
    vi: 'Không lấy được thông tin theo giới tính',
  },
  getTimeZoneInfoFailed: {
    ja: '時間帯別の情報取得に失敗しました',
    en: 'Failed to get information by time zone',
    zhcn: '未能通过时区获取信息',
    zhtw: '未能通過時區獲取信息',
    ko: '시간 대별 정보 취득에 실패했습니다',
    th: 'ไม่สามารถดึงข้อมูลตามเขตเวลาได้',
    vi: 'Không lấy được thông tin theo múi giờ',
  },
  cannotUpdateOtherUserComment: {
    ja: '更新に失敗しました他ユーザのコメントは編集できません',
    en: "Failed to update. Can not update other user's comment.",
    zhcn: '无法更新。无法更新其他用户意见',
    zhtw: '無法更新。無法更新其他用戶意見',
    ko: '업데이트에 실패했습니다 다른 사용자의 댓글은 수정 할 수 없습니다',
    th: 'อัปเดตไม่สำเร็จ ไม่สามารถแก้ไขความคิดเห็นของผู้ใช้อื่นได้',
    vi: 'Cập nhật thất bại. Không thể cập nhật bình luận của người dùng khác.',
  },
  cannotUpdateOtherUserCommentDisclaimer: {
    ja: '他のユーザのコメントは編集できません',
    en: "Can not edit other user's comment.",
    zhcn: '无法编辑其他用户意见',
    zhtw: '無法編輯其他用戶意見',
    ko: '다른 사용자의 댓글은 수정 할 수 없습니다',
    th: 'ไม่สามารถแก้ไขความคิดเห็นของผู้ใช้อื่นได้',
    vi: 'Không thể chỉnh sửa bình luận của người dùng khác.',
  },
  cannotDeleteOtherUserComment: {
    ja: '削除に失敗しました他ユーザのコメントは削除できません',
    en: "Failed to delete. Can not delete other user's comment.",
    zhcn: '删除失败。无法删除其他用户意见',
    zhtw: '刪除失敗。無法刪除其他用戶意見',
    ko: '삭제에 실패했습니다 다른 사용자의 댓글은 삭제할 수 없습니다',
    th: 'ลบไม่สำเร็จ ไม่สามารถลบความคิดเห็นของผู้ใช้อื่นได้',
    vi: 'Xóa thất bại. Không thể xóa bình luận của người dùng khác.',
  },
  systemSettings: {
    ja: 'システム設定',
    en: 'System Settings',
    zhcn: '系统设定',
    zhtw: '系統設定',
    ko: '시스템 설정',
    th: 'การตั้งค่าระบบ',
    vi: 'Cài đặt hệ thống',
  },
  activateAndCreateUser: {
    ja: '承認 + ユーザー作成',
    en: 'Approve + Create User',
    zhcn: '批准 + 创建用户',
    zhtw: '批准 + 建立使用者',
    ko: '승인 + 사용자 생성',
    th: 'อนุมัติ + สร้างผู้ใช้',
    vi: 'Phê duyệt + Tạo người dùng',
  },
  activateOnly: {
    ja: '承認のみ',
    en: 'Approve only',
    zhcn: '仅批准',
    zhtw: '僅核准',
    ko: '승인만',
    th: 'อนุมัติเฉพาะ',
    vi: 'Chỉ phê duyệt',
  },
  rejectLocation: {
    ja: '差し戻し',
    en: 'Reject',
    zhcn: '拒絕',
    zhtw: '拒絕',
    ko: '거부하다',
    th: 'ปฏิเสธ',
    vi: 'Từ chối',
  },
  rejectReason: {
    ja: '差し戻し理由',
    en: 'Reject reason',
    zhcn: '拒绝原因',
    zhtw: '拒絕原因',
    ko: '거부 이유',
    th: 'เหตุผลในการปฏิเสธ',
    vi: 'Lý do từ chối',
  },
  deactivateLocation: {
    ja: '販売停止',
    en: 'Deactivate',
    zhcn: '停止銷售',
    zhtw: '停止銷售',
    ko: '판매 중단',
    th: 'ยกเลิกการใช้งาน',
    vi: 'Hủy kích hoạt',
  },
  deactivateReason: {
    ja: '販売停止理由',
    en: 'Deactivate reason',
    zhcn: '停止销售的原因',
    zhtw: '停止銷售的原因',
    ko: '판매 중단 사유',
    th: 'เหตุผลในการยกเลิกการใช้งาน',
    vi: 'Lý do hủy kích hoạt',
  },
  locationStatus: {
    ja: '拠点ステータス',
    en: 'Customer Status',
    zhcn: '基地状况',
    zhtw: '基地状况',
    ko: '고객 상태',
    th: 'สถานะลูกค้า',
    vi: 'Trạng thái khách hàng',
  },
  locationStatusReviewing: {
    ja: '未承認',
    en: 'Inactive',
    zhcn: '未批准',
    zhtw: '未批准',
    ko: '미승인',
    th: 'ไม่ทำงาน',
    vi: 'Không hoạt động',
  },
  locationStatusActivated: {
    ja: '承認済',
    en: 'Activated',
    zhcn: '已确认',
    zhtw: '已確認',
    ko: '승인 완료',
    th: 'เปิดใช้งานแล้ว',
    vi: 'Đã kích hoạt',
  },
  locationStatusPending: {
    ja: '再承認待ち',
    en: 'Pending',
    zhcn: '待定',
    zhtw: '待定',
    ko: '보류 중',
    th: 'รอดำเนินการ',
    vi: 'Đang chờ xử lý',
  },
  activateLocationMessage: {
    ja: '拠点は承認されました',
    en: 'Location was activated',
    zhcn: '地点已停止銷售',
    zhtw: '地點已停止銷售',
    ko: '지점이 판매 중단',
    th: 'สถานที่ถูกเปิดใช้งานแล้ว',
    vi: 'Địa điểm đã được kích hoạt',
  },
  deactivateLocationMessage: {
    ja: 'レストランが販売停止となりました',
    en: 'Location was deactivated',
    zhcn: '地点已确认',
    zhtw: '地點已確認',
    ko: '지점이 승인되었습니다',
    th: 'สถานที่ถูกยกเลิกการใช้งาน',
    vi: 'Địa điểm đã được hủy kích hoạt',
  },
  rejectLocationMessage: {
    ja: '申請をを却下しました',
    en: 'Location was rejected',
    zhcn: '地点已拒绝',
    zhtw: '地點已拒绝',
    ko: '지점이 거부 이유',
    th: 'สถานที่ถูกปฏิเสธ',
    vi: 'Địa điểm đã bị từ chối',
  },
  storageServiceInfo: {
    ja: 'ストレージ/サービス情報',
    en: 'Storage /Service Information',
    zhcn: '存储/服务信息',
    zhtw: '存儲/服務信息',
    ko: '보관 / 서비스 정보',
    th: 'ข้อมูลการจัดเก็บ/บริการ',
    vi: 'Thông tin lưu trữ/Dịch vụ',
  },
  tokenIsInvalid: {
    ja: 'セッションの有効期限が切れました。恐れ入りますが再度ログインしてください。',
    en: 'Session has expired. Please login again.',
    zhcn: '会话已过期。 很抱歉，请重新登录。',
    zhtw: '會話已過期。 很抱歉，請重新登錄。',
    ko: '세션이 만료되었습니다. 다시 로그인하십시오.',
    th: 'เซสชันหมดอายุแล้ว โปรดเข้าสู่ระบบอีกครั้ง',
    vi: 'Phiên đã hết hạn. Vui lòng đăng nhập lại.',
  },
});

function LangSettings<T extends { [key: string]: { [key in TSupportedLanguages] } }>(arg: T): T {
  return arg;
}
