import { i18n } from '@i18n/lang';
import { Dialog, DialogContent } from '@material-ui/core';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { formatDate } from '@utils/format-date';
import { Button } from '@visual/button';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class DahsboardSummaryPopup extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showPopup: false,
    lang: LANGUAGE_DEFAULT,
    serialsList: [],
    alerts: [],
    dist: false,
  };

  public render(): JSX.Element {
    const {
      onConfirmationButtonClick,
      onCancelClick,
      onSendClick,
      showPopup,
      dist,
      onClose,
      lang,
      serialsList,
      alerts,
      locations,
    } = this.props;

    const buttons = [
      onConfirmationButtonClick && (
        <Button onClick={onConfirmationButtonClick}>{i18n.confirm[lang]}</Button>
      ),
      onSendClick && (
        <Button onClick={onSendClick}>{i18n.sendWithTheFollowingContents[lang]}</Button>
      ),
      onCancelClick && (
        <Button color='secondary-light4' onClick={onCancelClick}>
          {i18n.cancel[lang]}
        </Button>
      ),
    ]
      .filter((button) => button)
      .map((button, i) => (
        <div key={hash(i)} className={styles.button}>
          {button}
        </div>
      ));
    let shipmentCount = {};
    if (serialsList) {
      if (dist) {
        for (var key in serialsList) {
          if (shipmentCount[serialsList[key].slip]) {
            shipmentCount[serialsList[key].slip] += 1;
          } else {
            shipmentCount[serialsList[key].slip] = 1;
          }
        }
      } else {
        for (var key in serialsList) {
          if (serialsList[key].distSlip) {
            if (shipmentCount[serialsList[key].distSlip]) {
              shipmentCount[serialsList[key].distSlip] += 1;
            } else {
              shipmentCount[serialsList[key].distSlip] = 1;
            }
          } else {
            if (shipmentCount[serialsList[key].slip]) {
              shipmentCount[serialsList[key].slip] += 1;
            } else {
              shipmentCount[serialsList[key].slip] = 1;
            }
          }
        }
      }
    }

    return (
      <Dialog open={showPopup} onClose={onClose}>
        <DialogContent style={{ padding: '0', fontFamily: 'sans-serif' }}>
          <div className={styles.notificationDetailPopup} style={{ fontFamily: 'sans-serif' }}>
            <div className={styles.title}>
              <TextDisplay color='primary' weight='bold' size='x-large'>
                {i18n.notification[lang]}
              </TextDisplay>
            </div>
            <div className={styles.content}>
              <span className={styles.white}>
                <p style={{ fontFamily: 'sans-serif' }}>
                  <span
                    style={{
                      backgroundColor: '#ff0000',
                      width: 'auto',
                      padding: '2px',
                      fontWeight: 'bold',
                      fontSize: 'large',
                    }}
                  >
                    {i18n.unprocessedAlert[lang]}
                  </span>
                </p>
              </span>
              <TextDisplay color='black'>
                <pre style={{ fontFamily: 'sans-serif' }}>
                  <table style={{ fontSize: 'large' }}>
                    <tr>
                      <td>{i18n.receivingScanDelayAlert2[lang]}</td>
                      <td>: </td>
                      <td>
                        {alerts.DELAY}
                        {i18n.piece[lang]}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.inventoryQualityAlert2[lang]}</td>
                      <td>: </td>
                      <td>
                        {alerts.QUALITY}
                        {i18n.piece[lang]}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.gpsAlert2[lang]}</td>
                      <td>: </td>
                      <td>
                        {alerts.GPS}
                        {i18n.piece[lang]}
                      </td>
                    </tr>
                  </table>
                </pre>
              </TextDisplay>
              <span className={styles.white}>
                <p style={{ fontFamily: 'sans-serif' }}>
                  <span
                    style={{
                      backgroundColor: '#15355c',
                      width: 'auto',
                      padding: '2px',
                      fontWeight: 'bold',
                      fontSize: 'large',
                    }}
                  >
                    {i18n.bottleToBeDelivered[lang]}
                  </span>
                </p>
              </span>
              <TextDisplay color='black'>
                <pre style={{ fontFamily: 'sans-serif' }}>
                  <div style={{ fontSize: 'large' }}>
                    {serialsList.length > 0
                      ? this.getContents(serialsList, shipmentCount, dist, lang, locations)
                      : i18n.none[lang]}
                  </div>
                </pre>
              </TextDisplay>
            </div>
            <div className={styles.buttonContainer}>{buttons}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getContents(
    serialsList: any,
    shipmentCount: any,
    dist: boolean,
    lang: string,
    locations: any,
  ): JSX.Element {
    if (!shipmentCount) {
      return;
    }
    if (dist) {
      //If login user is distributor
      return serialsList.map((serial, i) => {
        const { brewLoc, brewShipAt, slip } = serial;
        if (shipmentCount[slip] >= 1) {
          const count = shipmentCount[slip];
          shipmentCount[slip] = 0;
          return (
            <div className={styles?.rcorners}>
              <div>
                {i18n.shippedFrom[lang]} : {brewLoc.name}
              </div>
              <div>
                {i18n.number[lang]} : {count}
                {i18n.bottles[lang]}
              </div>
              <div>
                {i18n.shippingDate[lang]} : {formatDate(brewShipAt)}
              </div>
            </div>
          );
        }
      });
    } else {
      //If login user is Restaurant
      return serialsList.map((serial, i) => {
        const {
          brewShipDistLoc,
          brewLoc,
          brewShipAt,
          distShipAt,
          slip,
          distSlip,
          recvDistLoc,
        } = serial;
        if (distSlip) {
          if (shipmentCount[distSlip] >= 1) {
            const count = shipmentCount[distSlip];
            shipmentCount[distSlip] = 0;
            console.log(JSON.stringify(serial, null, 4));
            return (
              <div className={styles?.rcorners}>
                <div>
                  {i18n.shippedFrom[lang]} :{' '}
                  {brewShipDistLoc?.name ? brewShipDistLoc?.name : recvDistLoc?.name}
                </div>
                <div>
                  {i18n.number[lang]} : {count}
                  {i18n.bottles[lang]}
                </div>
                <div>
                  {i18n.shippingDate[lang]} : {formatDate(distShipAt)}
                </div>
              </div>
            );
          }
        } else {
          if (shipmentCount[slip] >= 1) {
            const count = shipmentCount[slip];
            shipmentCount[slip] = 0;
            return (
              <div className={styles?.rcorners}>
                <div>
                  {i18n.shippedFrom[lang]} :{' '}
                  {brewShipDistLoc?.name ? brewShipDistLoc?.name : brewLoc?.name}

                </div>
                <div>
                  {i18n.number[lang]} : {count}
                  {i18n.bottles[lang]}
                </div>
                <div>
                  {i18n.shippingDate[lang]} : {formatDate(brewShipAt)}
                </div>
              </div>
            );
          }
        }
      });
    }
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  showPopup?: boolean;
  lang?: TSupportedLanguages;
  dist: boolean;
  serialsList?: any;
  alerts?: any;
  locations?: any;
}

export interface IDispatchProps {
  onConfirmationButtonClick?: () => void;
  onSendClick?: () => void;
  onCancelClick?: () => void;
  onClose?: () => void;
}
